import CheckIcon from "@mui/icons-material/Check";
import { Box, Chip, Menu, MenuItem, Tooltip } from "@mui/material";
import { useState } from "react";
import { severity } from "../labels";
import { SeverityType } from "../state";

interface SeverityButtonProps {
  selectedSeverity: SeverityType;
  onSeverityChange: (severity: SeverityType) => void;
  tooltip?: string;
  showIcon?: boolean;
}

const SeverityButton = ({ selectedSeverity, onSeverityChange, tooltip, showIcon = false }: SeverityButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleChipClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSeverityChange = (severity: SeverityType) => {
    onSeverityChange(severity);
    setAnchorEl(null);
  };

  const displayedSeverity = selectedSeverity.toUpperCase();

  return (
    <Box>
      <Tooltip title={tooltip ? `${displayedSeverity} - ${tooltip}` : undefined}>
        <Chip
          icon={showIcon ? <CheckIcon /> : undefined}
          size="small"
          label={displayedSeverity}
          sx={{
            backgroundColor: severity[displayedSeverity]?.color,
            color: "black",
            ":hover": { backgroundColor: severity[displayedSeverity]?.color, opacity: "0.8" },
          }}
          onClick={handleChipClick}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.keys(severity).map((key) => {
          const lowerKey = key.toLowerCase() as SeverityType;
          return (
            <MenuItem key={lowerKey} selected={lowerKey === selectedSeverity} onClick={() => handleSeverityChange(lowerKey)}>
              {key}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default SeverityButton;
