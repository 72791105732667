import { GridInitialState } from "@mui/x-data-grid-pro";
import { createContext, useContext, useState, ReactNode, useEffect, useRef } from "react";
import { useWsContext } from "./ws-context";
import { extractPrimaryLanguage } from "./Components/charts/network-graphs/NetworkGraphs";
interface ClientContextType {
  tempView: GridInitialState;
  setTempView: (view: GridInitialState) => void;
  stringToColor: (str: string) => string;
  analysisCurrentView: string;
  setAnalysisCurrentView: React.Dispatch<React.SetStateAction<string>>;
  categoryColorMap: Record<string, string>;
  languageColorMap: Record<string, string>;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const useClientContext = (): ClientContextType => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClientContext must be used within a ClientContextProvider");
  }
  return context;
};

export const ClientContextProvider = ({ children }: { children: ReactNode }) => {
  const [tempView, setTempView] = useState<GridInitialState>({});
  const [narrativeColorMap, setNarrativeColorMap] = useState<Record<string, string>>({});
  const [categoryColorMap, setCategoryColorMap] = useState<Record<string, string>>({});
  const [languageColorMap, setLanguageColorMap] = useState<Record<string, string>>({});
  const [analysisCurrentView, setAnalysisCurrentView] = useState("");
  const { dashboardState, mentionsState } = useWsContext();
  const previousTagsRef = useRef<string[]>([]);
  const previousCategoryRef = useRef<string[]>([]);
  const previousLanguagesRef = useRef<string[]>([]);

  const generateColorMap = (tags: string[]) => {
    const uniqueTags = Array.from(new Set(tags)).sort((a, b) => a.localeCompare(b));
    const newColorMap: Record<string, string> = {};
    const lightnessVariants = [60, 65, 70, 75, 80, 85];
    const hueStep = 360 / uniqueTags.length;
    uniqueTags.forEach((tag, index) => {
      const hue = Math.round(index * hueStep) % 360;
      const lightness = lightnessVariants[index % lightnessVariants.length];
      newColorMap[tag] = `hsl(${hue}, 75%, ${lightness}%)`;
    });
    return newColorMap;
  };

  useEffect(() => {
    const aiQuestionsTags = dashboardState?.ai_questions?.map((q) => q.label) || [];
    const monitoringTags = Array.from(
      new Set(mentionsState.flatMap((mention) => mention.ai_filter?.results?.map((result) => result.key) || []).filter(Boolean))
    );
    const potentialNarrativesTags = Array.from(
      new Set(mentionsState.flatMap((mention) => mention.potential_narratives?.map((n) => n.label) || []).filter(Boolean))
    );
    const combinedTags = Array.from(new Set([...aiQuestionsTags, ...monitoringTags, ...potentialNarrativesTags]));
    if (JSON.stringify(previousTagsRef.current) === JSON.stringify(aiQuestionsTags)) return;
    setNarrativeColorMap(generateColorMap(combinedTags));
    previousTagsRef.current = aiQuestionsTags;
  }, [dashboardState.ai_questions, mentionsState]);

  useEffect(() => {
    const aiCategoryTags = (dashboardState?.ai_questions?.map((q) => q.category).filter(Boolean) as string[]) || [];
    if (JSON.stringify(previousCategoryRef.current) === JSON.stringify(aiCategoryTags)) return;
    setCategoryColorMap(generateColorMap(aiCategoryTags));
    previousCategoryRef.current = aiCategoryTags;
  }, [dashboardState.ai_questions]);

useEffect(() => {
  const detectedLanguages = Array.from(
    new Set(mentionsState.map((mention) => extractPrimaryLanguage(mention.language)))
  );
  if (JSON.stringify(previousLanguagesRef.current) === JSON.stringify(detectedLanguages)) return;
  setLanguageColorMap(generateColorMap(detectedLanguages));
  previousLanguagesRef.current = detectedLanguages;
}, [mentionsState]);

  const stringToColor = (str: string): string => {
    return narrativeColorMap[str] || "#808080";
  };

  return (
    <ClientContext.Provider
      value={{
        tempView,
        setTempView,
        stringToColor,
        analysisCurrentView,
        setAnalysisCurrentView,
        categoryColorMap,
        languageColorMap,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};
