import DocsTable from "./DocsTable";
import AuditLogs from "./AuditLogs";
import Alerts from "./Alerts";
import SectionCard from "./SectionCard";
import { Box, Typography, Alert, Link } from "@mui/material";
import { useEffect } from "react";
import { GlobalSizes } from "../../size";
import FlaggingDocs from "./FlaggingDocs";
const ApiDocumentation = () => {
  const EnpointNavigation = () => (
    <Box sx={{ margin: GlobalSizes.gap, marginBottom: GlobalSizes.gap * 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        EndPoints:
      </Typography>
      •{" "}
      <Link sx={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleScrollToSection("alerts")}>
        Alerts
      </Link>
      <br />•{" "}
      <Link sx={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleScrollToSection("audit")}>
        Audit Logs
      </Link>
      <br />•{" "}
      <Link sx={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => handleScrollToSection("flagging")}>
        Flagging
      </Link>
    </Box>
  );

  const handleScrollToSection = (id: string) => {
    window.history.replaceState(null, "", `#${id}`);
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (!hash) return;

    setTimeout(() => {
      document.getElementById(hash)?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 0);
  }, []);

  return (
    <Box p={3}>
      <Alert severity="info" sx={{ marginLeft: 0, maxWidth: "1500px", mb: 3 }}>
        Contact Brinker to obtain your API key and API URL.
      </Alert>

      <EnpointNavigation />

      <SectionCard title="Authentication">
        <Typography variant="body1">Include the API key provided by Brinker in the request headers.</Typography>
        <DocsTable data={[{ "Property Key": "Authorization", value: "{{your-api-key}}" }]} />
      </SectionCard>

      <Box id="alerts">
        <Alerts />
      </Box>

      <Box id="audit">
        <AuditLogs />
      </Box>

      <Box id="flagging">
        <FlaggingDocs />
      </Box>
    </Box>
  );
};

export default ApiDocumentation;
