import { Box, Card, CardContent, Chip, IconButton, Link, Stack, Tooltip, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import EditIcon from "@mui/icons-material/Edit";
import { AIQuestionType } from "../../state";
import { useClientContext } from "../../client-context";
import NarrativeFooter from "./NarrativeFooter";
import NarrativeSeverity from "./NarrativeSeverity";

interface NarrativeProps {
  setInEditMode: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  question: AIQuestionType;
}

const Narrative = (props: NarrativeProps) => {
  const { setInEditMode, index, question } = props;
  const { stringToColor } = useClientContext();

  return (
    <Card sx={{ mb: GlobalSizes.gap, position: "relative" }}>
      <CardContent sx={{ mb: GlobalSizes.gap }}>
        <Box position="absolute" top={GlobalSizes.smallGap} right={GlobalSizes.smallGap} mt={GlobalSizes.smallGap} mr={GlobalSizes.smallGap}>
          <Stack direction="row" alignItems="center" gap={GlobalSizes.smallGap}>
            <NarrativeSeverity narrative={question} index={index} />
            <Tooltip title="Edit" placement="top">
              <IconButton size="small" onClick={() => setInEditMode(index)}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h5" component="div" mb={GlobalSizes.smallGap}>
            <Chip
              label={`${question.label}`}
              sx={{
                backgroundColor: stringToColor(question.label),
                color: "black",
                fontSize: GlobalSizes.bigFontSize,
                height: GlobalSizes.bigChipHeight,
                padding: GlobalSizes.buttonPadding,
              }}
            />
          </Typography>
          {(["question", "mitigation", "link", "category"] as const).map((key) =>
            key === "link" ? (
              <Link key={key} variant="body2" my={GlobalSizes.smallGap}>
                {question.link}
              </Link>
            ) : (
              <Typography
                key={key}
                variant="body2"
                color={key === "mitigation" ? "GrayText" : undefined}
                my={GlobalSizes.smallGap}
                sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                {`${key === "category" && question[key] ? "Category: " : ""}${question[key]}`}
              </Typography>
            )
          )}
        </Box>
      </CardContent>
      <NarrativeFooter question={question} />
    </Card>
  );
};

export default Narrative;
