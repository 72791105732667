import { Box, Card, CardContent, IconButton, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../size";
import CheckIcon from "@mui/icons-material/Check";
import { AIQuestionType } from "../../state";
import { useWsContext } from "../../ws-context";
import NarrativeFieldsForm from "./NarrativeFieldsForm";

interface NewNarrativeProps {
  localQuestions: AIQuestionType[];
  setLocalQuestions: React.Dispatch<React.SetStateAction<AIQuestionType[]>>;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  newQuestion: AIQuestionType;
  setNewQuestion: React.Dispatch<React.SetStateAction<AIQuestionType>>;
  setInEditMode: React.Dispatch<React.SetStateAction<number>>;
}

const NewNarrative = (props: NewNarrativeProps) => {
  const { localQuestions, setLocalQuestions, error, setError, newQuestion, setNewQuestion, setInEditMode } = props;
  const { updateDashboard, emitNewData, dashboardState } = useWsContext();

  const handleAddQuestion = async () => {
    if (!newQuestion.label || !newQuestion.question) return;
    const updatedQuestions = [newQuestion, ...localQuestions];
    setLocalQuestions(updatedQuestions);
    updateDashboard({ ai_questions: updatedQuestions.reverse() });
    setNewQuestion((prev) => ({ ...prev, label: "", question: "", mitigation: "", link: "", category: "" }));
    setInEditMode(-1);
    emitNewData({ action: "setAIQuestion", question: newQuestion });
  };

  return (
    <Card sx={{ mb: GlobalSizes.gap, display: "flex", flexDirection: "column", position: "relative" }}>
      <CardContent sx={{ flexGrow: GlobalSizes.smallGap, mb: GlobalSizes.gap }}>
        <Box display="flex" justifyContent="flex-end" width={GlobalSizes.fullSize} mb={GlobalSizes.smallGap}>
          <Tooltip title="Save" placement="top">
            <IconButton
              disabled={error}
              size="small"
              onClick={handleAddQuestion}
              data-track="new_narrative"
              data-event-track={dashboardState.ai_questions?.[dashboardState.ai_questions.length - 1]?.label}
            >
              <CheckIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        <NarrativeFieldsForm
          localQuestions={localQuestions}
          setLocalQuestions={setLocalQuestions}
          error={error}
          setError={setError}
          question={newQuestion}
          setNewQuestion={setNewQuestion}
        />
      </CardContent>
    </Card>
  );
};

export default NewNarrative;
