import { useMemo } from "react";
import { MentionType } from "../../../state";
import BarChartView from "./BarChartView";
import { useClientContext } from "../../../client-context";

export type DatasetType = Array<{ source: string } & Record<string, number>>;

interface BarChartProps {
  mentions: MentionType[];
}

const buildBarChartData = (mentions: MentionType[]) => {
  const dataset = mentions.reduce<{ [source: string]: Record<string, number> }>((acc, mention) => {
    if (!mention.source) return acc;
    if (!acc[mention.source]) acc[mention.source] = {};
    mention.ai_filter?.results?.forEach((result) => {
      acc[mention.source!][result.key] = (acc[mention.source!][result.key] || 0) + 1;
    });
    return acc;
  }, {});

  return Object.entries(dataset)
    .map(([source, data]) => ({
      source,
      ...data,
      totalCount: Object.values(data).reduce((acc, count) => acc + count, 0),
    }))
    .sort((a, b) => b.totalCount - a.totalCount)
    .map(({ totalCount, ...rest }) => rest) as DatasetType;
};

function BarChart({ mentions }: BarChartProps) {
  const { stringToColor } = useClientContext();
  const { barChartData, relevantCategories } = useMemo(() => {
    const datasetArr = buildBarChartData(mentions);
    const relevantCategories = Array.from(new Set(datasetArr.flatMap((data) => Object.keys(data)))).filter((key) => key !== "source");

    const barChartData: DatasetType = datasetArr.map((data) => {
      relevantCategories.forEach((key) => {
        if (!data[key]) {
          data[key] = 0;
        }
      });
      return data;
    });

    return { barChartData, relevantCategories };
  }, [mentions]);

  const series = useMemo(
    () =>
      relevantCategories.map((key) => ({
        id: key,
        label: key,
        dataKey: key,
        stack: "total",
        color: stringToColor(key),
      })),
    [relevantCategories, stringToColor]
  );

  return (
    <BarChartView
      title={`Narrative Distribution by Source (${mentions.length} alerts)`}
      dataset={barChartData}
      series={series}
      showLegend={series.length <= 5}
    />
  );
}

export default BarChart;
