import { useMemo } from "react";
import { MentionType } from "../../../state";
import { severity } from "../../../labels";
import LineChartView from "./LineChartView";

function SeverityLineChart({ mentions }: { mentions: MentionType[] }) {
  const mentionsFromPastYear = useMemo(
    () => mentions.filter((m) => m.creation_date && new Date(m.creation_date) >= new Date(new Date().setMonth(new Date().getMonth() - 12))),
    [mentions]
  );

  const { dates, countsByDate } = useMemo(() => {
    const countsByDate: { [key: string]: number[] } = {
      LOW: [],
      MEDIUM: [],
      HIGH: [],
      CRITICAL: [],
    };

    const dates = Array.from(new Set(mentionsFromPastYear.map((mention) => new Date(mention.creation_date!).setHours(0, 0, 0, 0)))).sort();

    dates.forEach((date) => {
      Object.keys(severity).forEach((sev) => {
        const count = mentionsFromPastYear.filter((mention) => {
          return new Date(mention.creation_date!).setHours(0, 0, 0, 0) === date && mention.severity && mention.severity.toUpperCase() === sev;
        }).length;
        countsByDate[sev].push(count);
      });
    });
    return { dates, countsByDate };
  }, [mentionsFromPastYear]);

  return <LineChartView title={`Severity Timeline (${mentions.length} alerts)`} countsByDate={countsByDate} dates={dates} />;
}

export default SeverityLineChart;
