import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import Groups2Icon from "@mui/icons-material/Groups2";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { useAuth0, User } from "@auth0/auth0-react";
import { Avatar, Collapse } from "@mui/material";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { useWsContext } from "../ws-context";
import { labels } from "../labels";
import { NavLink, useLocation } from "react-router-dom";
import { FaGavel, FaHeartbeat } from "react-icons/fa";
import { Troubleshoot, Api } from "@mui/icons-material";
import { avatarSize } from "../size";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { useState } from "react";
import { TeamType } from "../state";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const DRAWER_STYLES = {
  width: 250,
  backgroundColor: "#233476",
  selectedItem: {
    "&.Mui-selected": {
      backgroundColor: "#465ab3",
      "&:hover": {
        backgroundColor: "#465ab3",
      },
    },
  },
  typography: {
    primary: { fontSize: 13 },
    secondary: { fontSize: 9 },
  },
  hover: {
    "&:hover": {
      backgroundColor: "#3a4d99",
      color: "white",
    },
  },
  textColor: "white",
};

const createListItems = (user?: User, team?: TeamType) => ({
  topList: [
    { path: "/", icon: <Avatar variant="square" src={"/logo.png"} sx={{ width: 32, height: 32 }} />, title: "Brinker", subTitle: "Dashboard" },
    { path: "/assets", icon: labels.assets.icon, title: labels.assets.title, subTitle: labels.assets.subtitle },
    { path: "/narratives", icon: <PsychologyAltIcon />, title: labels.ai.title, subTitle: labels.ai.subtitle },
    { path: "/monitoring", icon: <FaHeartbeat />, title: labels.monitoring.title, subTitle: labels.monitoring.subtitle },
    { path: "/analysis", icon: <ScatterPlotIcon />, title: labels.analysis.title, subTitle: labels.analysis.subtitle },
    { path: "/investigation", icon: <Troubleshoot />, title: labels.investigation.title, subTitle: labels.investigation.subtitle },
    { path: "/action", icon: <FaGavel />, title: labels.takeAction.title, subTitle: labels.takeAction.subtitle },
  ],
  bottomList: [
    { path: "/team", icon: <Groups2Icon />, title: 'Team Settings', subTitle: team?.teamName || labels.teamManagement.title },
    { path: "/siem-integration", icon: <CrisisAlertIcon />, title: labels.siemIntegration.title, subTitle: labels.siemIntegration.subtitle },
    { path: "/api", icon: <Api />, title: labels.api.title, subTitle: labels.api.subtitle },
  ],
});

function CustomListItem({ icon, title, subTitle, path }: { icon: JSX.Element; title?: string; subTitle?: string; path: string }) {
  const location = useLocation();
  const basePath = path.split("?")[0];
  return (
    <ListItem disablePadding dense>
      <ListItemButton component={NavLink} to={path} selected={location.pathname === basePath} sx={[DRAWER_STYLES.selectedItem, DRAWER_STYLES.hover]}>
        <ListItemIcon sx={{ color: "white" }}>{icon}</ListItemIcon>
        <ListItemText
          primary={title}
          secondary={subTitle}
          primaryTypographyProps={{
            sx: DRAWER_STYLES.typography.primary,
            color: DRAWER_STYLES.textColor,
          }}
          secondaryTypographyProps={{
            sx: DRAWER_STYLES.typography.secondary,
            color: DRAWER_STYLES.textColor,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

function SidePanel({ user }: { user?: User }) {
  const { logout } = useAuth0();
  const { dashboardState, currentAlert } = useWsContext();
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const logoutUser = () => {
    localStorage.clear();
    indexedDB.databases().then((databases) => {
      databases.forEach((db) => {
        if (!db.name) return;
        indexedDB.deleteDatabase(db.name);
        console.log(`Deleted database: ${db.name}`);
      });
    });
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getNavLinkWithQuery = (path: string) => {
    return currentAlert ? `${path}?url=${encodeURIComponent(currentAlert.url)}` : `${path}`;
  };

  const listItems = createListItems(user, dashboardState.team);

  return (
    <Drawer
      sx={{
        width: DRAWER_STYLES.width,
        [`& .MuiDrawer-paper`]: {
          width: DRAWER_STYLES.width,
          backgroundColor: "#233476",
          borderRight: "1px solid #e0e0e0",
        },
      }}
      anchor="left"
      variant="permanent"
    >
      <ListItem disablePadding divider>
        <ListItemButton component={NavLink} to={"/team"} selected={location.pathname === "/team"} sx={[DRAWER_STYLES.selectedItem, DRAWER_STYLES.hover]}>
          <ListItemText
            primary={dashboardState.team?.teamName || labels.teamManagement.title}
            primaryTypographyProps={{
              sx: {
                textAlign: "center",
                color: DRAWER_STYLES.textColor,
              },
            }}
            secondaryTypographyProps={{
              sx: {
                textAlign: "center",
                fontSize: DRAWER_STYLES.typography.secondary.fontSize,
                color: DRAWER_STYLES.textColor,
              },
            }}
          />
        </ListItemButton>
      </ListItem>
      <List>
        {listItems.topList.map((item) => (
          <CustomListItem key={item.title} {...item} path={getNavLinkWithQuery(item.path)} />
        ))}
      </List>
      <Divider />
      <ListItem component="div" disablePadding>
        <ListItemButton sx={{ color: DRAWER_STYLES.textColor }} onClick={() => setOpen(!open)}>
          <ListItemIcon sx={{ color: DRAWER_STYLES.textColor }}>
            <Avatar src={user?.picture as string} sx={avatarSize.smallerAvatar} />
          </ListItemIcon>
          <ListItemText primary={user?.name || user?.email} secondary={'Settings'}
            primaryTypographyProps={{
              sx: DRAWER_STYLES.typography.primary,
              color: DRAWER_STYLES.textColor,
            }}
            secondaryTypographyProps={{
              sx: DRAWER_STYLES.typography.secondary,
              color: DRAWER_STYLES.textColor,
            }} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {listItems.bottomList.map((item) => (
            <CustomListItem key={item.title} {...item} path={getNavLinkWithQuery(item.path)} />
          ))}
          <ListItem disablePadding dense>
            <ListItemButton onClick={logoutUser} sx={[DRAWER_STYLES.selectedItem, DRAWER_STYLES.hover]}>
              <ListItemIcon sx={{ color: DRAWER_STYLES.textColor }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                secondary={user?.email}
                primaryTypographyProps={{
                  sx: DRAWER_STYLES.typography.primary,
                  color: DRAWER_STYLES.textColor,
                }}
                secondaryTypographyProps={{
                  sx: DRAWER_STYLES.typography.secondary,
                  color: DRAWER_STYLES.textColor,
                }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Collapse>
    </Drawer>
  );
}

export default SidePanel;
