import { ScatterChart } from "@mui/x-charts/ScatterChart";
import { MentionType } from "../../state";
import { MakeOptional } from "@mui/x-charts/internals";
import { ScatterItemIdentifier, ScatterSeriesType, ScatterValueType } from "@mui/x-charts";
import { useMemo, useState } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../size";
import MentionFocus from "../MentionFocus";
import { useWsContext } from "../../ws-context";
import { useClientContext } from "../../client-context";
type PointType = ScatterValueType & { mention: MentionType };

function EmbeddingsScatterChart({ mentions }: { mentions: MentionType[] }) {
  const [seriesData, setSeriesData] = useState<ScatterItemIdentifier | null>(null);
  const { setSidebarAlert } = useWsContext();
  const { stringToColor } = useClientContext();

  const mentionsWithPotentialNarratives = useMemo(() => mentions.filter((mention) => mention.potential_narratives && mention.reduced_embedding), [mentions]);

  const mentionsByInquiry = mentionsWithPotentialNarratives.reduce<{ [key: string]: MentionType[] }>((groupedMentions, mention) => {
    mention.potential_narratives!.forEach(({ label }) => {
      if (!groupedMentions[label]) groupedMentions[label] = [];
      groupedMentions[label].push(mention);
    });
    return groupedMentions;
  }, {});

  const series: MakeOptional<ScatterSeriesType, "type">[] = Object.entries(mentionsByInquiry).map(([key, value]) => {
    return {
      label: key,
      id: key,
      color: stringToColor(key),
      data: value.map((mention) => {
        return {
          x: mention.reduced_embedding![0],
          y: mention.reduced_embedding![1],
          id: mention.url,
          mention,
        };
      }),
      highlightScope: {
        highlight: "item",
      },
      markerSize: GlobalSizes.smallGap * 5,
      valueFormatter: (value) => {
        const point = series.find((s) => s.data?.some((d) => d.id === value.id))?.data?.find((d) => d.id === value.id) as PointType | undefined;
        return point?.mention
          ? `${point.mention.user ? point.mention.user + " - " : ""}${point.mention.source} - ${point.mention.description_short || point.mention.full_content}`
          : "";
      },
    };
  });

  return (
    <Box width={GlobalSizes.cards.width} height={GlobalSizes.fullScreenHeight}>
      <Tooltip title="Filtered in the monitoring view">
        <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
          New Narrative Clusters ({mentionsWithPotentialNarratives.length} alerts)
        </Typography>
      </Tooltip>
      <ScatterChart
        height={600}
        series={series}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        onItemClick={(event: React.MouseEvent<SVGElement, MouseEvent>, d: ScatterItemIdentifier) => {
          setSeriesData(d);
          if (!seriesData) return;
          setSidebarAlert((series.find((s) => s.id === seriesData.seriesId)?.data?.[seriesData.dataIndex] as PointType)?.mention);
        }}
      />
      {seriesData && <MentionFocus />}
    </Box>
  );
}

export default EmbeddingsScatterChart;
