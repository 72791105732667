import { Box, Button, Container } from "@mui/material";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import { useEffect, useState } from "react";
import { AIQuestionType } from "../state";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { useAuth0 } from "@auth0/auth0-react";
import { useWsContext } from "../ws-context";
import { unparse } from "papaparse";
import { saveAs } from "file-saver";
import MentionFocus from "../Components/MentionFocus";
import NewNarrative from "../Components/narratives/NewNarrative";
import EditNarrative from "../Components/narratives/EditNarrative";
import Narrative from "../Components/narratives/Narrative";

function Narratives() {
  const [localQuestions, setLocalQuestions] = useState<AIQuestionType[]>([]);
  const [addingQuestion, setAddingQuestion] = useState(false);
  const [error, setError] = useState(false);
  const [inEditMode, setInEditMode] = useState(-1);

  const { dashboardState } = useWsContext();
  const { user } = useAuth0();

  const [newQuestion, setNewQuestion] = useState<AIQuestionType>({
    label: "",
    question: "",
    mitigation: "",
    link: "",
    category: "",
    last_update: Date.now(),
    last_updated_by: user?.email,
  });

  const exportToCSV = () => {
    const csvData = dashboardState.ai_questions!.map((q) => ({
      Label: q.label,
      Question: q.question,
      Category: q.category,
      Last_Update: q.last_update,
      Last_Updated_By: q.last_updated_by,
      Link: q.link,
      Mitigation: q.mitigation,
    }));

    const csvString = unparse(csvData);
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "narratives.csv");
  };

  useEffect(() => {
    if (dashboardState.ai_questions) {
      setLocalQuestions([...dashboardState.ai_questions].reverse() || []);
    }
  }, [dashboardState.ai_questions]);

  return (
    <div>
      <Bar label={`${labels.ai.title} | ${labels.ai.subtitle}`} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Box display="flex" justifyContent="space-between" sx={{ mb: GlobalSizes.gap }}>
          <Button
            variant="contained"
            color={addingQuestion ? "secondary" : "primary"}
            onClick={() => {
              if (addingQuestion) {
                setNewQuestion((prev) => ({ ...prev, label: "", question: "", mitigation: "", link: "" }));
              }
              setAddingQuestion(!addingQuestion);
              setError(false);
            }}
          >
            {addingQuestion ? "Cancel" : `Add ${labels.ai.single_item}`}
          </Button>
          {localQuestions.length > 0 && (
            <Button variant="outlined" color="primary" onClick={exportToCSV}>
              Export to CSV
            </Button>
          )}
        </Box>
        {addingQuestion && (
          <NewNarrative
            localQuestions={localQuestions}
            setLocalQuestions={setLocalQuestions}
            error={error}
            setError={setError}
            newQuestion={newQuestion}
            setNewQuestion={setNewQuestion}
            setInEditMode={setInEditMode}
          />
        )}
        {localQuestions.map((question, index) =>
          inEditMode === index ? (
            <EditNarrative
              key={index}
              localQuestions={localQuestions}
              setLocalQuestions={setLocalQuestions}
              error={error}
              setError={setError}
              index={index}
              question={question}
              setInEditMode={setInEditMode}
            />
          ) : (
            <Narrative key={index} index={index} question={question} setInEditMode={setInEditMode} />
          )
        )}
      </Container>
      <MentionFocus />
    </div>
  );
}

export default Narratives;
