import { Stack, Tooltip, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { SOCIAL_INFO } from "../labels";
import { GlobalSizes } from "../size";

export default function CollectionCoverage({ coverageSources }: { coverageSources: string[] | undefined }) {
  const SourceListItem = ({ source }: { source: string }) => {
    const { name, icon: SourceIcon } = SOCIAL_INFO[source];

    const isEnabled = coverageSources?.includes(source);
    const tooltipTitle = isEnabled ? `${name} (enabled)` : `${name} (disabled)`;

    return (
      <Tooltip title={tooltipTitle}>
        <span>
          <ToggleButton value={source} disabled>
            <SourceIcon fontSize="small" />
          </ToggleButton>
        </span>
      </Tooltip>
    );
  };

  return (
    <Stack alignSelf="flex-end" direction="row" alignItems="center" spacing={GlobalSizes.smallGap}>
      <Typography variant="caption">Coverage:</Typography>
      <ToggleButtonGroup
        size="small"
        aria-label="Platform"
        value={coverageSources}
      >
        {Object.keys(SOCIAL_INFO).map((source) => (
          <SourceListItem key={source} source={source} />
        ))}
      </ToggleButtonGroup>
    </Stack>

  );
}
