import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, Typography, Link, Box } from "@mui/material";
import { GlobalSizes } from "../../size";
import { MentionType } from "../../state";
import AssetHighlighter from "../assets/AssetHighlighter";
import { labels, unixToLocaleDate, engagementCount } from "../../labels";
import TranslateButton from "../TranslateButton";

function GeneralView({ alert }: { alert?: MentionType }) {
  if (!alert) return null;
  return (
    <Card>
      <CardContent>
        <CardHeader
          sx={{ pl: GlobalSizes.none }}
          avatar={<Avatar alt={alert?.user} src={alert?.avatar} />}
          title={alert?.user}
          subheader={unixToLocaleDate(alert.creation_date)}
        />
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <AssetHighlighter text={alert?.description_short} asset={alert?.asset || ""} />
        </Typography>
        <Box mt={0.2} mb={GlobalSizes.smallGap}>
          <TranslateButton language={alert?.language} content={alert?.description_short} />
        </Box>
        <Link
          href={alert?.url}
          target="_blank"
          rel="noreferrer"
          textOverflow="ellipsis"
          maxWidth={GlobalSizes.fullSize}
          overflow="hidden"
          display="inline-block"
          whiteSpace="nowrap"
        >
          {alert?.url}
        </Link>
        <CardActions>
          <Chip size="small" title="Monitored Asset" icon={labels.assets.icon} variant="outlined" label={alert.asset} />
          {!!alert.language && <Chip size="small" title="Item Language" variant="outlined" label={alert.language} />}
          {!!alert.impressions && (
            <Chip
              size="small"
              variant="outlined"
              label={`~${engagementCount(alert.impressions)} ${alert.impressions_type?.replace("_", " ") || "impressions"}`}
            />
          )}
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default GeneralView;
