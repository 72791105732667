import { BarChart } from "@mui/x-charts";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../../size";
import CustomLegend from "../CustomLegend";

interface BarChartViewProps {
  title: string;
  showLegend?: boolean;
  dataset: Array<{ source: string } & Record<string, number>>;
  series: { id: string; label: string; dataKey: string; stack: string; color: string }[];
}

const BarChartView = ({ title, showLegend, dataset, series }: BarChartViewProps) => {
  return (
    <Box width={GlobalSizes.cards.width} height={GlobalSizes.fullScreenHeight}>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        {title}
      </Typography>
      {showLegend && <CustomLegend labels={series.map((s) => ({ name: s.label, color: s.color }))} />}
      <BarChart
        dataset={dataset}
        layout="horizontal"
        margin={{ left: 100 }}
        yAxis={[
          {
            scaleType: "band",
            dataKey: "source",
          },
        ]}
        series={series}
        height={GlobalSizes.chartHeight}
        tooltip={{ trigger: "item" }} // Set tooltip trigger to 'item'
        slotProps={{ legend: { hidden: true } }}
        skipAnimation
      />
    </Box>
  );
};

export default BarChartView;
