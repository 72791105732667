import { Box, Slider } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import DirectedAcyclicGraph from "./DirectedAcyclicGraph";
import EmbeddingsNetworkChart from "./EmbeddingsNetworkChart";
import EmbeddingsNetworkChart2D from "./EmbeddingsNetworkChart2D";
import { engagementCount, similarityThreshold } from "../../../labels";
import { MentionType } from "../../../state";
import { useWsContext } from "../../../ws-context";
import { GlobalSizes } from "../../../size";
import { useClientContext } from "../../../client-context";

export type NetworkNode = {
  id: string;
  name: string;
  user_name?: string;
  image?: string;
  source?: string;
  creation_date?: number;
  val: number;
  impressions?: number;
  impressions_type?: string;
  language: string;
};
type NetworkLink = {
  source: string;
  target: string;
  similarity: number;
};

export type NetworkGraphDataType = {
  nodes: NetworkNode[];
  links: NetworkLink[];
};

export const getNodeSize = (impressions: number) => {
  if (impressions > 1000) return 9;
  if (impressions > 100) return 6;
  return 4;
};

export const extractPrimaryLanguage = (languageStr?: string) =>
  languageStr
    ?.split(",")
    .map((lang) => lang.trim())
    .find((lang) => lang.toLowerCase() !== "english") || "English";

const formatDate = (date: number) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" });
};

function NetworkGraphs({
  mentions,
  graph,
}: {
  mentions: MentionType[];
  range?: [number, number];
  setShouldDisplaySlider?: React.Dispatch<React.SetStateAction<boolean>>;
  graph?: string;
}) {
  const { setSidebarAlert } = useWsContext();
  const { languageColorMap } = useClientContext();
  const [selectedNode, setSelectedNode] = useState<string | null>(null);

  const [minDate, maxDate] = useMemo(() => {
    if (!mentions.length) return [0, 0];
    const dates = mentions.filter((mention) => mention.creation_date).map((mention) => mention.creation_date as number);
    return [Math.min(...dates), Math.max(...dates)];
  }, [mentions]);

  const [range, setRange] = useState<[number, number]>([minDate, maxDate]);

  const marks = useMemo(() => {
    const step = (maxDate - minDate) / 4;
    return Array.from({ length: 5 }, (_, i) => {
      const value = minDate + step * i;
      return { value, label: formatDate(value) };
    });
  }, [minDate, maxDate]);

  const handleRangeChange = (_: Event, newRange: number | number[]) => {
    if (!Array.isArray(newRange)) return;
    setRange(newRange as [number, number]);
  };

  const languagePalette = useMemo(
    () => Object.fromEntries(Array.from(new Set(mentions.map((m) => extractPrimaryLanguage(m.language)))).map((lang) => [lang, languageColorMap[lang]])),
    [mentions, languageColorMap]
  );

  const memoizedTopMentionsWithSimilarity = useMemo(
    () => mentions.filter((m) => m.creation_date && (m.similar?.[0]?.score || 0) > similarityThreshold).slice(0, 1000),
    [mentions]
  );

  const mentionsWithAssetAndNarrative = useMemo(() => {
    return mentions.filter((mention) => mention.asset && mention.ai_filter?.results?.length).slice(0, 1000);
  }, [mentions]);

  const handleMentionClick = useCallback(
    (node: any) => {
      console.log({ node });
      const mention = mentions.find((m) => m.url === node.id);
      if (mention) {
        setSidebarAlert(mention);
        setSelectedNode(node.id);
      }
    },
    [mentions, setSidebarAlert, setSelectedNode]
  );

  const handleBackgroundClick = useCallback(() => {
    setSelectedNode(null);
  }, [setSelectedNode]);

  const mapSimilarityToOpacity = (s: number) => {
    const simMin = 0.7,
      simMax = 1.0,
      opMin = 0.1,
      opMax = 1.0;
    const clamped = Math.max(simMin, Math.min(simMax, s));
    return opMin + ((clamped - simMin) * (opMax - opMin)) / (simMax - simMin);
  };

  const linkColor = useCallback(
    (link: any) => {
      const sourceMention = memoizedTopMentionsWithSimilarity.find((m) => m.url === link.source.id);
      const targetMention = memoizedTopMentionsWithSimilarity.find((m) => m.url === link.target.id);

      if (!sourceMention || !targetMention) return "rgba(150, 150, 150, 0.1)";

      const sourceInRange = !range || (sourceMention.creation_date! >= range[0] && sourceMention.creation_date! <= range[1]);
      const targetInRange = !range || (targetMention.creation_date! >= range[0] && targetMention.creation_date! <= range[1]);

      if (!sourceInRange || !targetInRange) return "rgba(150, 150, 150, 0.1)";

      const crossSocialPlatform = sourceMention.source !== targetMention.source;
      const crossLanguage = extractPrimaryLanguage(sourceMention.language) !== extractPrimaryLanguage(targetMention.language);

      if (crossSocialPlatform && crossLanguage) return "rgba(255, 0, 255, 1)";
      if (crossSocialPlatform) return "rgba(0, 255, 255, 1)";
      if (crossLanguage) return "rgba(255, 255, 0, 1)";
      return `rgba(200, 200, 200, ${mapSimilarityToOpacity(link.similarity)})`;
    },
    [memoizedTopMentionsWithSimilarity, range]
  );

  const getTooltipBgColor = useCallback(
    (link: any) => {
      const color = linkColor(link);
      const allowedColors = [
        "rgba(255, 0, 255, 1)", // crossSocialPlatform && crossLanguage
        "rgba(0, 255, 255, 1)", // crossSocialPlatform
        "rgba(255, 255, 0, 1)", // crossLanguage
      ];
      return allowedColors.includes(color) ? color : "#ffffffee";
    },
    [linkColor]
  );

  const { networkGraphData } = useMemo(() => {
    const networkGraphData = {
      nodes: [],
      links: [],
    } as NetworkGraphDataType;

    const linkSet = new Set<string>();
    const mentionMap = new Map(memoizedTopMentionsWithSimilarity.map((m) => [m.url, m]));

    for (const mention of memoizedTopMentionsWithSimilarity) {
      networkGraphData.nodes.push({
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        user_name: mention.user,
        image: mention.avatar,
        source: mention.source,
        creation_date: mention.creation_date || 0,
        impressions: mention.impressions || 0,
        impressions_type: mention.impressions_type ? mention.impressions_type.replace("_", " ") : "impressions",
        language: extractPrimaryLanguage(mention.language),
        val: 1,
      });

      for (const similarMention of mention.similar || []) {
        if (similarMention.score < similarityThreshold) continue;

        const sourceMention = mentionMap.get(similarMention.url);
        if (!sourceMention || (sourceMention.creation_date || 0) >= (mention.creation_date || 0)) continue;

        const linkKey = `${sourceMention.url}-${mention.url}`;
        if (!linkSet.has(linkKey)) {
          linkSet.add(linkKey);
          networkGraphData.links.push({
            source: sourceMention.url,
            target: mention.url,
            similarity: similarMention.score,
          });
        }
      }
    }

    return { networkGraphData };
  }, [memoizedTopMentionsWithSimilarity]);

  const formatNodeTooltip = (node: NetworkNode): string => {
    const nameText = node.name;
    const usernameText = node.user_name ? `@${node.user_name}` : "";
    const sourceText = node.source || "Manually collected";
    const langText = node.language ? `(${node.language.slice(0, 2).toUpperCase()})` : "";
    const impressionsText = `~${engagementCount(node.impressions ?? 0)} ${node.impressions_type || "impressions"}`;

    return `${sourceText} (${impressionsText}) - ${nameText} ${usernameText} ${langText}`;
  };

  const formatLinkTooltip = (link: any): string => {
    const source = link.source;
    const target = link.target;
    const platformInfo = source?.source !== target?.source ? `[${source?.source}→${target?.source}]` : "";
    const languageInfo = source?.language !== target?.language ? `[${source?.language}→${target?.language}]` : "";
    const similarity = `${(link.similarity * 100).toFixed(2)}% similar`;
    return `${similarity} ${platformInfo} ${languageInfo}`;
  };

  const commonGraphProps = useMemo(
    () => ({
      selectedNode,
      onNodeClick: handleMentionClick,
      onBackgroundClick: handleBackgroundClick,
      linkColor,
      range,
      languagePalette,
      formatNodeTooltip,
      formatLinkTooltip,
      getTooltipBgColor,
    }),
    [selectedNode, handleMentionClick, handleBackgroundClick, range, linkColor, languagePalette, getTooltipBgColor]
  );

  return (
    <Box ml={GlobalSizes.gap}>
      {graph !== "Acyclic" && (
        <Box display="flex" justifyContent="flex-end" mr={12} mb={GlobalSizes.gap}>
          <Slider
            getAriaLabel={() => "Creation Date Range"}
            value={range}
            min={minDate}
            max={maxDate}
            onChange={handleRangeChange}
            valueLabelDisplay="auto"
            sx={{ width: "45vw" }}
            marks={marks}
            valueLabelFormat={formatDate}
          />
        </Box>
      )}
      {graph === "Network (2D)" && <EmbeddingsNetworkChart2D networkGraphData={networkGraphData} {...commonGraphProps} />}
      {graph === "Network (3D)" && <EmbeddingsNetworkChart networkGraphData={networkGraphData} {...commonGraphProps} />}
      {graph === "Acyclic" && <DirectedAcyclicGraph mentions={mentionsWithAssetAndNarrative} {...commonGraphProps} />}
    </Box>
  );
}

export default NetworkGraphs;
