import { ChangeEvent, useEffect, useState } from "react";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import {
  Container,
  IconButton,
  InputBase,
  Paper,
  List,
  ListItem,
  Card,
  Typography,
  CardContent,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../Components/Loader";
import { TeamType } from "../state";
import { GlobalSizes, TeamSizes, pagesContainerMargins } from "../size";
import { MenuItem } from "@mui/material";
import { useAuth0, User } from "@auth0/auth0-react";
import PersonIcon from "@mui/icons-material/Person";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { setTeam } from "../team-util";
import { useWsContext } from "../ws-context";

function Team() {
  const { user } = useAuth0();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialTeam, setInitialTeam] = useState<TeamType>(undefined);
  const [localTeam, setLocalTeam] = useState<TeamType>(undefined);

  const { dashboardState, updateDashboard, emitNewData, socketRef } = useWsContext();

  const updateTeam = async () => {
    try {
      setIsLoading(true);
      updateDashboard({ team: localTeam });
      emitNewData({ action: "setTeam", team: localTeam });
      setInitialTeam(localTeam);
    } catch (error) {
      console.log("Failed to Update team data on the server.");
    } finally {
      setIsLoading(false);
    }
  };

  const addNewTeam = async () => {
    try {
      setIsLoading(true);
      emitNewData({ action: "newTeam", team: { users: [(user as User).email!] } });
      const handleWebSocketMessage = async (event: MessageEvent) => {
        const { data } = JSON.parse(event.data);
        if (data?.newTeam) {
          if (!data.newTeam.customer_id) {
            console.log("Response does not contain customer_id");
          } else {
            selectTeam(data.newTeam.customer_id);
          }
          socketRef.current?.removeEventListener("message", handleWebSocketMessage);
        }
      };
      socketRef.current?.addEventListener("message", handleWebSocketMessage);
    } catch (error) {
      console.log("Failed to add new team");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddUser = async (email: string) => {
    if (!email) return;
    if (!dashboardState.team?.users?.includes(email)) {
      const newTeam = { ...dashboardState.team, users: [...(dashboardState.team?.users || []), email] };
      emitNewData({
        action: "setTeam",
        team: newTeam,
      });
      setLocalTeam(newTeam);
    }
    setEmail("");
  };

  const handleDeleteUser = (emailToDelete: string) => {
    const newTeam = { ...localTeam, users: localTeam?.users?.filter((email) => email !== emailToDelete) };
    setLocalTeam(newTeam);
    emitNewData({
      action: "setTeam",
      team: newTeam,
    });
  };

  const handleTeamNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLocalTeam({
      ...localTeam,
      teamName: e.target.value,
    });
  };

  useEffect(() => {
    if (dashboardState.team) {
      setLocalTeam(dashboardState.team);
      setInitialTeam(dashboardState.team);
    } else {
      setLocalTeam({ teamName: "", users: [] });
    }
  }, [dashboardState.team]);

  const selectTeam = (chosenTeamId: string) => {
    if (socketRef.current) {
      emitNewData({ action: "disconnect" });
    }
    console.log({ chosenTeamId });
    setTeam(chosenTeamId);
    window.location.replace(`/?team=${chosenTeamId}`);
  };

  if (!user) return null;
  function normalize(customer: { customerId: string; team: TeamType }) {
    return customer.team?.teamName || customer.customerId;
  }

  return (
    <>
      <Bar label={labels.teamManagement.title + " | " + labels.teamManagement.subtitle} />
      <Container sx={{ ...pagesContainerMargins }}>
        <FormControl variant="filled" sx={{ mt: GlobalSizes.gap, mb: GlobalSizes.gap, width: GlobalSizes.fullSize }}>
          <InputLabel id="team-select-label">Switch team</InputLabel>
          <Select labelId="team-select-label" id="team-select" value="" onChange={(e) => selectTeam(e.target.value as string)} label="Switch team">
            <MenuItem key={user.email} value={user.email}>
              {<PersonIcon />}&nbsp;{user.email}
            </MenuItem>
            {dashboardState?.accessibleTeams &&
              dashboardState.accessibleTeams
                .sort((t1, t2) => {
                  return normalize(t1).localeCompare(normalize(t2));
                })
                .map((accessibleTeam) => (
                  <MenuItem key={accessibleTeam.customerId} value={accessibleTeam.customerId}>
                    {accessibleTeam.customerId === dashboardState.customer_id ? (
                      <CheckBoxIcon color="primary" fontSize="small" />
                    ) : (
                      <CheckBoxOutlineBlankIcon fontSize="small" color="disabled" />
                    )}
                    &nbsp;
                    {normalize(accessibleTeam)}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <Typography variant="h4" sx={{ mt: GlobalSizes.gap }}>
          Team settings
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: GlobalSizes.gap }}>
          Team name
        </Typography>
        <Card sx={{ my: GlobalSizes.gap }}>
          <CardContent>
            <Paper
              component="form"
              sx={{
                mt: GlobalSizes.gap,
              }}
              onSubmit={(e) => e.preventDefault()}
            >
              {" "}
              <InputBase
                sx={{
                  border: `${GlobalSizes.borderWidth} solid ${!localTeam?.teamName ? "#ff0033" : ""}`,
                  borderRadius: TeamSizes.borderRadius,
                  p: GlobalSizes.gap,
                  width: GlobalSizes.fullSize,
                }}
                placeholder={"Set team name"}
                inputProps={{ "aria-label": "team name" }}
                value={localTeam?.teamName || ""}
                onChange={(e) => {
                  handleTeamNameChange(e);
                }}
              />
            </Paper>
            <Button
              variant="contained"
              style={{ display: !localTeam?.teamName || isLoading || initialTeam?.teamName === localTeam?.teamName ? "none" : "block" }}
              onClick={() => {
                updateTeam();
              }}
              data-track="teamName_change"
              data-event-track={localTeam?.teamName}
            >
              Save name
            </Button>
          </CardContent>
        </Card>
        <Loader />
        <Typography variant="subtitle1" sx={{ mt: GlobalSizes.gap }}>
          Team members
        </Typography>
        <List dense sx={{ mt: GlobalSizes.gap }}>
          {localTeam?.users?.map((email, i) => (
            <Card key={i} sx={{ mb: GlobalSizes.gap, display: "flex" }}>
              <ListItem sx={{ p: GlobalSizes.gap, justifyContent: "space-between" }}>
                <Typography variant="h6" component="div">
                  {email}
                </Typography>{" "}
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteUser(email)}
                  data-track="delete_user"
                  data-event-track={dashboardState.team?.users?.[i]}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            </Card>
          ))}
        </List>
        <Paper
          component="form"
          sx={{
            px: GlobalSizes.gap,
            display: "flex",
            alignItems: "center",
            mb: GlobalSizes.gap,
            mt: GlobalSizes.gap,
          }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAddUser(email);
          }}
        >
          <PersonIcon />
          <InputBase
            sx={{ ml: GlobalSizes.gap, flex: GlobalSizes.flex, padding: GlobalSizes.gap }}
            placeholder="Enter new user email"
            type="email"
            inputProps={{ "aria-label": "User's email" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" type="submit" sx={{ p: GlobalSizes.buttonPadding }} aria-label="add" data-track="new_user" data-event-track={email}>
            Add user
          </Button>
        </Paper>
        {isLoading && <CircularProgress />}

        <Divider sx={{ mt: 10 }} />
        <Typography variant="h4" sx={{ mt: GlobalSizes.gap }}>
          Streamline Asset Management Across Teams
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: GlobalSizes.gap }}>
          Organize assets, users, and narratives into dedicated teams to enhance security, accountability, and operational efficiency in your organization.
        </Typography>
        <Button
          sx={{ height: TeamSizes.selectHeight, borderTopLeftRadius: GlobalSizes.none, borderBottomLeftRadius: GlobalSizes.none }}
          disabled={isLoading || (dashboardState.customer_id !== user.email && !localTeam?.teamName)}
          onClick={() => addNewTeam()}
        >
          Create New Team
        </Button>
        <Divider sx={{ mt: 10 }} />
        <Typography variant="subtitle2" mt={GlobalSizes.gap}>
          Team ID: {dashboardState.customer_id}
        </Typography>
      </Container>
    </>
  );
}

export default Team;
