import { MentionType } from "../../state";
import { Typography, Stack } from "@mui/material";
import { GlobalSizes } from "../../size";
import Metadata from "../Metadata";

function Overview({ alert }: { alert?: MentionType }) {
  if (!alert?.mitigation_general) return null;
  return (
    <Stack mt={GlobalSizes.gap} gap={GlobalSizes.gap}>
      <Typography variant="h4">Overview</Typography>
      <Metadata alert={alert} />
      <Typography style={{ whiteSpace: "pre-line" }}>{alert.mitigation_general}</Typography>
    </Stack>
  );
}

export default Overview;
