import { MentionType } from "../../../state";
import ForceGraph2D, { ForceGraphMethods } from "react-force-graph-2d";
import { Box, Typography, Tooltip } from "@mui/material";
import { TYPES_ICONS, severity, SOCIAL_INFO, ARTICLES_LOGO } from "../../../labels";
import MentionFocus from "../../MentionFocus";
import { useEffect, useMemo, useRef, useState } from "react";
import { similarityThreshold } from "../../../labels";
import { renderToString } from "react-dom/server";
import { useTheme } from "@mui/material";
import { useNetworkChart } from "../../../useNetworkChart";
import { useClientContext } from "../../../client-context";

function DirectedAcyclicGraph({
  mentions,
  selectedNode,
  onNodeClick,
  onBackgroundClick,
}: {
  mentions: MentionType[];
  selectedNode: string | null;
  onNodeClick: (node: any) => void;
  onBackgroundClick: () => void;
}) {
  const [imgCache, setImgCache] = useState<{ [key: string]: HTMLImageElement }>({});
  const [selectedUserNode, setSelectedUserNode] = useState<string | null>(null);
  const graphRef = useRef<ForceGraphMethods<any, any> | undefined>(undefined);
  const [isZoomedOut, setIsZoomedOut] = useState(false);
  const [highlightedPlatforms, setHighlightedPlatforms] = useState<Set<string>>(new Set());
  const [narrativeColor, setNarrativeColor] = useState<string | null>(null);
  const theme = useTheme();
  const { stringToColor } = useClientContext();
  const nodeFillColor = theme.palette.mode === "light" ? "#4f4f4f" : "#c0c0c0";
  const linkFillColor = theme.palette.mode === "light" ? "#c0c0c0" : "#4f4f4f";
  const logo = theme.palette.mode === "light" ? "logo900.png" : "logo.png";
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart(selectedNode);
  const mentionCounts: Record<string, number> = {};

  const preRenderedIcons = useMemo(() => {
    const renderIcon = (IconComponent: React.ElementType) =>
      renderToString(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={nodeFillColor}>
          <IconComponent />
        </svg>
      );

    const svgToDataUrl = (svg: string) => {
      const blob = new Blob([svg], { type: "image/svg+xml" });
      return URL.createObjectURL(blob);
    };

    const allIcons = { ...Object.fromEntries(Object.entries(SOCIAL_INFO).map(([key, value]) => [key, value.icon])), ...TYPES_ICONS };
    const preRendered: Record<string, string> = {};

    for (const [key, IconComponent] of Object.entries(allIcons)) {
      const svg = renderIcon(IconComponent);
      preRendered[key] = svgToDataUrl(svg);
    }

    return preRendered;
  }, [nodeFillColor]);

  // Clear the image cache when the theme changes
  useEffect(() => {
    setImgCache({});
  }, [theme.palette.mode]);

  const sortedMentionsWithAssetAndNarrative = useMemo(() => {
    return [
      ...mentions.filter((mention) => mention.type === "profile"), // Profiles first
      ...mentions.filter((mention) => mention.type !== "profile"),
    ];
  }, [mentions]);

  const { myData } = useMemo(() => {
    const myData = {
      nodes: [] as any[],
      links: [] as any[],
    };

    const narrativeNodes = new Set<string>();

    // Add root node
    myData.nodes.push({
      id: "Root",
      image: logo,
    });

    const getMentionImage = (mention: MentionType) => {
      if (!mention.type) return preRenderedIcons["post"];
      if (mention.type === "profile") return mention.avatar || preRenderedIcons["profile"];
      return preRenderedIcons[mention.type];
    };

    for (const mention of sortedMentionsWithAssetAndNarrative) {
      // Create mention node
      const mentionNode = {
        id: mention.url,
        name: mention.description_short || mention.title || mention.url,
        image: getMentionImage(mention),
        source: mention.source,
        user: mention.user_id || `${mention.user}-${mention.source}`,
        type: mention.type,
        label: mention.user,
      };
      myData.nodes.push(mentionNode);

      let platformNodeId: string | undefined;
      let userNodeId: string;

      // Add source node
      if (mention.source) {
        platformNodeId = `source-${mention.source}`;
        if (!myData.nodes.find((node) => node.id === platformNodeId)) {
          myData.nodes.push({
            id: platformNodeId,
            name: mention.source,
            image: mention.type !== "article" ? preRenderedIcons[mention.source] : ARTICLES_LOGO[mention.source] || mention.avatar,
            source: "Source",
          });
        }
      }

      if (mention.type !== "article") {
        // Add user node and links
        if (mention.type === "profile") {
          userNodeId = mention.url; // If the mention is of type "profile" use it as the user node
        } else {
          userNodeId = mention.user_id || `${mention.user}-${mention.source}`;
          let existingUserNode = myData.nodes.find((node) => node.id === userNodeId || (node.user === userNodeId && node.type === "profile"));
          if (!existingUserNode) {
            existingUserNode = {
              id: userNodeId,
              name: mention.user,
              image: mention.avatar || preRenderedIcons["profile"],
              source: "User",
            };
            myData.nodes.push(existingUserNode);
          }
          myData.links.push({
            source: existingUserNode.id,
            target: mention.url,
          });
        }
        myData.links.push({
          source: platformNodeId,
          target: userNodeId,
        });
      } else {
        myData.links.push({
          source: platformNodeId,
          target: mention.url,
        });
      }

      // Add narratives and links
      for (const narrative of mention.ai_filter?.results || []) {
        if (!narrativeNodes.has(narrative.key)) {
          narrativeNodes.add(narrative.key);
          myData.nodes.push({
            id: narrative.key,
            name: narrative.key,
            source: "Narrative",
          });
        }
        myData.links.push({
          source: "Root",
          target: narrative.key,
        });
        myData.links.push({
          source: narrative.key,
          target: platformNodeId,
        });
      }

      for (const similarMention of mention.similar || []) {
        if (mention.type === "profile") continue; // Skip the current mention if it's a profile
        if (similarMention.score < similarityThreshold) continue;
        const similarMentionData = sortedMentionsWithAssetAndNarrative.find((m) => m.url === similarMention.url);
        if (!similarMentionData || similarMentionData.type === "profile") continue; // Skip the similar mention if it's a profile

        const mentionDate = mention.creation_date || mention.detection_date || 0;
        const similarDate = similarMentionData.creation_date || similarMentionData.detection_date || 0;

        let source: string, target: string, similarity: number;

        if (mentionDate > similarDate) {
          source = similarMention.url;
          target = mention.url;
          similarity = similarMention.score;
        } else if (mentionDate < similarDate) {
          source = mention.url;
          target = similarMention.url;
          similarity = similarMention.score;
        } else {
          if (mention.url < similarMention.url) {
            source = mention.url;
            target = similarMention.url;
            similarity = similarMention.score;
          } else {
            source = similarMention.url;
            target = mention.url;
            similarity = similarMention.score;
          }
        }

        const existingLinkIndex = myData.links.findIndex((link) => link.source === source && link.target === target);
        if (existingLinkIndex === -1) {
          myData.links.push({
            source: source,
            target: target,
            similarity: similarity,
          });
        }
      }
    }

    return { myData };
  }, [sortedMentionsWithAssetAndNarrative, preRenderedIcons, logo]);

  myData.links.forEach((link) => {
    const sourceId = typeof link.source === "object" ? link.source.id : link.source;
    // If source is a user/profile node, count the mentions
    const sourceNode = myData.nodes.find((node) => node.id === sourceId);
    if (sourceNode && (sourceNode.source === "User" || sourceNode.type === "profile")) {
      mentionCounts[sourceId] = (mentionCounts[sourceId] || 0) + 1;
    }
  });
  // Attach mention count to relevant nodes
  myData.nodes.forEach((node) => {
    if (mentionCounts[node.id]) {
      node.mentionCount = mentionCounts[node.id];
    }
  });

  const rootNode = "Root";
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(() => {
    const initialExpandedNodes = new Set<string>([rootNode]);
    // Add all narrative nodes to the initial expanded nodes
    myData.nodes.forEach((node) => {
      if (node.source === "Narrative") {
        initialExpandedNodes.add(node.id);
      }
    });
    return initialExpandedNodes;
  });
  // BFS to derive "visible" nodes and links from expandedNodes
  const graphData = useMemo(() => {
    const adjList: Record<string, string[]> = {};
    myData.nodes.forEach((node) => {
      adjList[node.id] = [];
    });
    myData.links.forEach((link) => {
      // In this DAG, "link.source" represents the parent node and "link.target" represents the child node
      const sourceId = typeof link.source === "object" ? link.source.id : link.source;
      const targetId = typeof link.target === "object" ? link.target.id : link.target;
      if (!adjList[sourceId]) {
        adjList[sourceId] = [];
      }
      adjList[sourceId].push(targetId);
    });
    // BFS from the "Root" node, following expansions
    const visited = new Set<string>();
    const queue = [rootNode];
    visited.add(rootNode);
    while (queue.length > 0) {
      const nodeId = queue.shift()!;
      const children = adjList[nodeId] || [];
      // Only traverse children if nodeId is expanded
      if (expandedNodes.has(nodeId)) {
        for (const child of children) {
          if (!visited.has(child)) {
            visited.add(child);
            queue.push(child);
          }
        }
      }
    }
    // Filter myData to produce visible subgraph
    const visibleNodes = myData.nodes.filter((node) => visited.has(node.id));
    const visibleNodeIds = new Set(visibleNodes.map((node) => node.id));
    const visibleLinks = myData.links.filter((link) => {
      const sourceId = typeof link.source === "object" ? link.source.id : link.source;
      const targetId = typeof link.target === "object" ? link.target.id : link.target;
      return visibleNodeIds.has(sourceId) && visibleNodeIds.has(targetId);
    });
    return { nodes: visibleNodes, links: visibleLinks };
  }, [myData, expandedNodes, rootNode]);

  const handleNodeClick = (node: any) => {
    onNodeClick(node);
    if (node.id !== "Root" && node.source !== "Narrative") {
      // Toggle expand/collapse
      setIsZoomedOut(false);
      setExpandedNodes((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(node.id)) {
          newSet.delete(node.id);
        } else {
          newSet.add(node.id);
        }
        if (node.source === "User") {
          setSelectedUserNode(newSet.has(node.id) ? node.id : null);
        }
        return newSet;
      });
    }
    // Highlight related platforms when a narrative node is clicked
    if (node.source === "Narrative") {
      const relatedPlatforms = new Set<string>();
      myData.links.forEach((link) => {
        const sourceId = typeof link.source === "object" ? link.source.id : link.source;
        const targetId = typeof link.target === "object" ? link.target.id : link.target;
        if (sourceId === node.id) {
          const targetNode = myData.nodes.find((n) => n.id === targetId);
          if (targetNode && targetNode.source === "Source") {
            relatedPlatforms.add(targetNode.id);
          }
        }
      });
      setHighlightedPlatforms(relatedPlatforms);
      setNarrativeColor(stringToColor(node.name));
    }
  };

  const handleBackgroundClick = () => {
    onBackgroundClick();
    setHighlightedPlatforms(new Set());
    setNarrativeColor(null);
  };

  function renderNodeCanvasObject(node: any, ctx: CanvasRenderingContext2D, globalScale: number) {
    const isSelected = selectedNode === node.id;
    const isUserNodeExpanded = expandedNodes.has(node.id) && node.source === "User";
    const isUserNodeSelected = selectedUserNode === node.id;
    const isHighlighted = highlightedPlatforms.has(node.id);
    const radius = 4 / globalScale;

    if (node.source === "Narrative") {
      const fontSize = radius * 2.5;
      ctx.font = `${fontSize}px Sans-Serif`;
      const textWidth = ctx.measureText(node.name).width;
      const padding = fontSize * 0.2;
      const backgroundDimensions = [textWidth + padding * 2, fontSize + padding * 2];
      ctx.fillStyle = stringToColor(node.name);
      ctx.fillRect(node.x - backgroundDimensions[0] / 2, node.y - backgroundDimensions[1] / 2, backgroundDimensions[0], backgroundDimensions[1]);
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(node.name, node.x, node.y);
      node.__bckgDimensions = backgroundDimensions;
      return;
    }

    if (isUserNodeExpanded && isUserNodeSelected) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, radius * 3, 0, 2 * Math.PI, false);
      ctx.fillStyle = "rgba(254, 254, 26, 0.6)";
      ctx.fill();
    }

    if (isSelected) {
      ctx.beginPath();
      ctx.arc(node.x, node.y, radius * 3, 0, 2 * Math.PI, false);
      ctx.fillStyle = "rgba(153, 102, 255, 0.6)";
      ctx.fill();
    }

    ctx.beginPath();
    ctx.arc(node.x, node.y, radius * 3, 0, 2 * Math.PI, false);
    ctx.fillStyle = isHighlighted && narrativeColor ? narrativeColor : "rgba(0, 0, 0, 0)";
    ctx.fill();

    const img = imgCache[node.id];
    if (img) {
      const size = radius * 4;
      ctx.save();
      ctx.beginPath();
      ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
      ctx.clip();
      ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
      ctx.restore();
    }

    if (node.mentionCount && (node.source === "User" || node.type === "profile")) {
      const fontSize = radius * 2.5;
      ctx.font = `${fontSize}px Sans-Serif`;
      ctx.fillStyle = theme.palette.mode === "light" ? "black" : "white";
      ctx.textAlign = "center";
      ctx.fillText(`${node.mentionCount} mention${node.mentionCount > 1 ? "s" : ""}`, node.x + radius * 9, node.y);
    }
  }

  useEffect(() => {
    myData.nodes.forEach((node) => {
      if (node.image && !imgCache[node.id]) {
        const img = new Image();
        img.src = node.image;
        img.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.id]: img }));
        img.onerror = () => {
          const fallbackImg = new Image();
          fallbackImg.src = preRenderedIcons["profile"];
          fallbackImg.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.id]: fallbackImg }));
        };
      }
    });
  }, [myData.nodes, imgCache, preRenderedIcons]);

  return (
    <Box>
      <Tooltip title="Filtered in the monitoring view">
        <Typography variant="h6" gutterBottom>
          AI Narrative Directed Acyclic Graph ({sortedMentionsWithAssetAndNarrative.length} alerts from top 1000 alerts)
        </Typography>
      </Tooltip>
      <ForceGraph2D
        ref={graphRef}
        graphData={graphData}
        width={window.innerWidth}
        height={window.innerHeight - 98}
        backgroundColor={theme.palette.background.paper}
        dagMode="lr"
        warmupTicks={100}
        cooldownTicks={2000}
        nodeColor={(node) => stringToColor(node.name)}
        nodeLabel={(node) => {
          if (node.id === "Root") return "Root";
          if (node.type === "profile") return `User - ${node.label} (Profile Alert)`;
          return `${node.source} - ${node.name}`;
        }}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkLabel={(link) => (link.similarity ? `${link.similarity?.toFixed(2) * 100 + "% similar"}` : "")}
        linkColor={(link) => {
          if (link.similarity) {
            if (link.similarity > 0.95) return severity.CRITICAL.color;
            if (link.similarity > 0.9) return severity.HIGH.color;
            if (link.similarity > 0.85) return severity.MEDIUM.color;
            return severity.LOW.color;
          }
          return linkFillColor;
        }}
        onEngineTick={() => {
          if (graphRef.current && !isZoomedOut) {
            graphRef.current.zoomToFit(500, 50);
            setIsZoomedOut(true);
          }
        }}
        onNodeClick={handleNodeClick}
        onBackgroundClick={handleBackgroundClick}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
        }}
        nodeCanvasObject={renderNodeCanvasObject}
      />
      <MentionFocus />
    </Box>
  );
}

export default DirectedAcyclicGraph;
