import { Container, Box } from "@mui/material";
import Bar from "../Components/Bar";
import { labels, MITIGATIONS } from "../labels";
import { GlobalSizes, pagesContainerMargins } from "../size";
import AlertSelect from "../Components/AlertSelect";
import GeneralView from "../Components/investigation/GeneralView";
import MitigationButtons from "../Components/action/MitigationButtons";
import MitigationTabs from "../Components/action/MitigationTabs";
import { useWsContext } from "../ws-context";
import { useCallback, useEffect, useState } from "react";
import { MentionType } from "../state";
import { useLocation } from "react-router-dom";

export type MitigationEntry = {
  promptId: string;
  emails: string[];
  supportedDomains?: string[];
};

export type MitigationKeys = keyof typeof MITIGATIONS;

const Action = () => {
  const { currentAlert } = useWsContext();
  const [selectedTab, setSelectedTab] = useState<string>();
  const { mentionsState, setCurrentAlert } = useWsContext();

  const location = useLocation();

  const findMitigationSources = useCallback(
    (parentMention: string, source: string | undefined) => {
      const sourceArray: string[] = source ? [source] : [];
      const childAlerts = mentionsState.filter((m) => m.parent_mention === parentMention).map((m) => m.url);

      childAlerts.forEach((key) => {
        try {
          let source = new URL(key.toLowerCase()).host;
          source = source.replace("www.", "");
          if (!sourceArray?.includes(source)) {
            sourceArray?.push(source);
          }
        } catch (error) {
          console.error(`Error parsing URL ${key}:`, error);
        }
      });
      return sourceArray;
    },
    [mentionsState]
  );

  const shouldRenderMitigationButton = useCallback(
    (mitigation: MitigationEntry, alert: MentionType) => {
      if (
        (mitigation.promptId === "mitigation_ai_content_analysis" || mitigation.promptId === "mitigation_counter_narrative") &&
        !alert.ai_filter?.results?.length
      ) {
        return false;
      }
      return (
        !mitigation.supportedDomains ||
        findMitigationSources(alert.url, alert.source)?.some((source) => mitigation.supportedDomains?.some((domain: string) => source === domain))
      );
    },
    [findMitigationSources]
  );

  const getFirstTab = useCallback(
    (alert: MentionType) => {
      const firstTabWithContent = Object.entries(MITIGATIONS).find(([, mitigation]) => {
        const mitigationContent = alert[mitigation.promptId as keyof MentionType] as string;
        return shouldRenderMitigationButton(mitigation, alert) && !!mitigationContent?.length;
      });
      return firstTabWithContent?.[0] || Object.keys(MITIGATIONS).find((key) => shouldRenderMitigationButton(MITIGATIONS[key as MitigationKeys], alert));
    },
    [shouldRenderMitigationButton]
  );

  useEffect(() => {
    const clickedAction = new URLSearchParams(location.search).get("url");

    const updateTabFromHash = () => {
      const tabFromUrl = decodeURIComponent(window.location.hash.replace("#", ""));
      const alert = mentionsState.find((m) => m.url === clickedAction);

      if (!alert) return;
      setCurrentAlert(alert);

      const validTab = MITIGATIONS[tabFromUrl as MitigationKeys] ? tabFromUrl : getFirstTab(alert);
      if (!validTab) return;
      setSelectedTab(validTab);
      window.location.hash = encodeURIComponent(validTab);
    };

    updateTabFromHash();
    window.addEventListener("hashchange", updateTabFromHash);

    return () => {
      window.removeEventListener("hashchange", updateTabFromHash);
    };
  }, [location.search, mentionsState, setCurrentAlert, getFirstTab]);

  return (
    <div>
      <Bar label={labels.takeAction.title + " | " + labels.takeAction.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Box sx={{ width: GlobalSizes.fullSize, typography: "body1" }}>
          <AlertSelect destination="action" />
          {currentAlert && (
            <Box>
              <MitigationButtons />
              <GeneralView alert={currentAlert} />
              <MitigationTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                shouldRenderMitigationButton={shouldRenderMitigationButton}
                getFirstTab={getFirstTab}
              />
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
};

export default Action;
