import ForceGraph2D from "react-force-graph-2d";
import { Box, Typography } from "@mui/material";
import MentionFocus from "../../MentionFocus";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import { useNetworkChart } from "../../../useNetworkChart";
import { getNodeSize, NetworkGraphDataType, NetworkNode } from "./NetworkGraphs";

function EmbeddingsNetworkChart2D({
  networkGraphData,
  selectedNode,
  onNodeClick,
  onBackgroundClick,
  linkColor,
  range,
  formatNodeTooltip,
  formatLinkTooltip,
  getTooltipBgColor,
}: {
  networkGraphData: NetworkGraphDataType;
  selectedNode: string | null;
  onNodeClick: (node: any) => void;
  onBackgroundClick: () => void;
  linkColor: (link: any) => string;
  range?: [number, number];
  formatNodeTooltip: (node: NetworkNode) => string;
  formatLinkTooltip: (link: any) => string;
  getTooltipBgColor: (link: any) => string;
}) {
  const [imgCache, setImgCache] = useState<{ [key: string]: HTMLImageElement }>({});
  const theme = useTheme();
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart(selectedNode);
  const [hoveredLink, setHoveredLink] = useState<any | null>(null);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const graphContainerRef = useRef<HTMLDivElement>(null);

  const nodeCanvasObject = useMemo(() => {
    return (node: any, ctx: CanvasRenderingContext2D) => {
      const isInRange = range ? node.creation_date >= range[0] && node.creation_date <= range[1] : true;
      const isSelected = selectedNode === node.id;

      const baseRadius = getNodeSize(node.impressions) / 2;

      const radius = isSelected ? baseRadius * 2 : baseRadius;

      if (!isInRange) {
        ctx.globalAlpha = 0.05;
      } else {
        ctx.globalAlpha = 1;
      }

      const img = imgCache[node.image];
      if (img) {
        const size = radius * 4;
        ctx.save();
        ctx.beginPath();
        ctx.arc(node.x, node.y, size / 2, 0, 2 * Math.PI, false);
        ctx.clip();
        ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
        ctx.restore();
      } else {
        ctx.beginPath();
        ctx.arc(node.x, node.y, radius * 1, 0, 2 * Math.PI, false);
        ctx.fillStyle = "grey";
        ctx.fill();
      }

      ctx.globalAlpha = 1;
    };
  }, [imgCache, range, selectedNode]);

  useEffect(() => {
    networkGraphData.nodes.forEach((node: any) => {
      if (node.image && !imgCache[node.image]) {
        const img = new Image();
        img.src = node.image;
        img.onload = () => setImgCache((prevCache) => ({ ...prevCache, [node.image]: img }));
      }
    });
  }, [imgCache, networkGraphData.nodes]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        AI Narrative Network (Showing {networkGraphData.nodes.length} from top 1000 alerts)
      </Typography>
      <div
        ref={graphContainerRef}
        style={{ position: "relative", display: "inline-block" }}
        onMouseMove={(e) => {
          const rect = graphContainerRef.current?.getBoundingClientRect();
          if (rect) {
            setMousePos({
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            });
          }
        }}
      >
        <ForceGraph2D
          graphData={networkGraphData}
          width={window.innerWidth}
          height={window.innerHeight - 98}
          backgroundColor={theme.palette.background.paper}
          warmupTicks={100}
          cooldownTime={2000} // Limits warm-up duration
          nodeRelSize={4}
          nodeLabel={(node) => formatNodeTooltip(node)}
          linkWidth={getLinkWidth}
          linkLabel={() => ""}
          onLinkHover={setHoveredLink}
          linkDirectionalParticles={getLinkParticles}
          linkDirectionalParticleWidth={getLinkParticleWidth}
          linkColor={linkColor}
          onNodeClick={onNodeClick}
          onBackgroundClick={onBackgroundClick}
          onNodeDragEnd={(node) => {
            node.fx = node.x;
            node.fy = node.y;
          }}
          nodeCanvasObject={nodeCanvasObject}
        />
        {hoveredLink && (
          <Box
            sx={{
              position: "absolute",
              top: mousePos.y + 12,
              left: mousePos.x - 12,
              backgroundColor: getTooltipBgColor(hoveredLink),
              color: "black",
              padding: "4px 6px",
              borderRadius: "4px",
              fontSize: "0.9rem",
              pointerEvents: "none",
              zIndex: 9999,
            }}
          >
            {formatLinkTooltip(hoveredLink)}
          </Box>
        )}
      </div>
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart2D;
