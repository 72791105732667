import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PlaceIcon from '@mui/icons-material/Place';
import { AiFilterType, IconMap, SocialInfo } from "./state";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import RedditIcon from "@mui/icons-material/Reddit";
import TelegramIcon from "@mui/icons-material/Telegram";
import CommentIcon from "@mui/icons-material/Comment";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import EventIcon from "@mui/icons-material/Event";
import FeedIcon from "@mui/icons-material/Feed";
import PostAddIcon from "@mui/icons-material/PostAdd";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import SecurityIcon from "@mui/icons-material/Security";
import React from "react";
import { VkIcon, GeminiIcon, ChatGPTIcon, WeiboIcon, QuoraIcon, TumblrIcon, WordPressIcon, RocketChatIcon, SignalMessengerIcon, DiscordIcon } from "./Components/Icons";

export const MITIGATIONS = {
  overview: { promptId: "mitigation_general", emails: [], mitigationDescription: "Information for General mitigation" },
  "counter narrative": { promptId: "mitigation_counter_narrative", emails: [], mitigationDescription: "Counter Narrative" },
  "alternate narrative": { promptId: "mitigation_ai_content_analysis", emails: [], mitigationDescription: "AI Content Analysis Mitigation" },
  facebook: {
    promptId: "mitigation_facebook",
    emails: ["abuse@fb.com", "legal@fb.com", "records@fb.com", "ip@fb.com", "press@fb.com"],
    supportedDomains: ["facebook.com", "fb.com"],
    mitigationDescription: "Take down request",
  },
  instagram: {
    promptId: "mitigation_instagram",
    emails: ["support@instagram.com", "legal@instagram.com"],
    supportedDomains: ["instagram.com", "instagr.am"],
    mitigationDescription: "Take down request",
  },
  "x (twitter)": { promptId: "mitigation_twitter", emails: [], supportedDomains: ["twitter.com", "x.com"], mitigationDescription: "Take down request" },
  linkedin: { promptId: "mitigation_linkedin", emails: [], supportedDomains: ["linkedin.com", "lnkd.in"], mitigationDescription: "Take down request" },
  tiktok: {
    promptId: "mitigation_tiktok",
    emails: ["info@tiktok.com", "legal@tiktok.com"],
    supportedDomains: ["tiktok.com"],
    mitigationDescription: "Take down request",
  },
  telegram: {
    promptId: "mitigation_telegram",
    emails: ["abuse@telegram.org"],
    supportedDomains: ["t.me"],
    mitigationDescription: "Take down request",
  },
  media: { promptId: "mitigation_media", emails: [], mitigationDescription: "Information for publication" },
  legal: { promptId: "mitigation_legal", emails: [], mitigationDescription: "Notification of Harmful Conduct" },
};

export const SOCIAL_INFO: { [key: string]: SocialInfo } = {
  "facebook.com": { name: "Facebook", icon: FacebookIcon },
  "twitter.com": { name: "X/Twitter", icon: TwitterIcon, alternate: "x.com" },
  "instagram.com": { name: "Instagram", icon: InstagramIcon },
  "linkedin.com": { name: "LinkedIn", icon: LinkedInIcon },
  "youtube.com": { name: "YouTube", icon: YouTubeIcon },
  "tiktok.com": { name: "TikTok", icon: MusicNoteIcon },
  "reddit.com": { name: "Reddit", icon: RedditIcon },
  "t.me": { name: "Telegram", icon: TelegramIcon },
  "vk.com": { name: "VK", icon: VkIcon },
  "newsOutlets": { name: "News Outlets", icon: NewspaperIcon },
  "Gemini": { name: "Gemini", icon: GeminiIcon },
  "ChatGPT": { name: "ChatGPT", icon: ChatGPTIcon },
  "WeiBo": { name: "WeiBo", icon: WeiboIcon },
  "Quora": { name: "Quora", icon: QuoraIcon },
  "WordPress": { name: "WordPress", icon: WordPressIcon },
  "Tumblr": { name: "Tumblr", icon: TumblrIcon },
  "Google Maps": { name: "Google Maps", icon: PlaceIcon },
  "RocketChat": { name: "RocketChat", icon: RocketChatIcon },
  "Signal": { name: "Signal", icon: SignalMessengerIcon },
  "Discord": { name: "Discord", icon: DiscordIcon },
};

export const TYPES_ICONS: IconMap = {
  comment: CommentIcon,
  post: PostAddIcon,
  profile: PersonIcon,
  page: FeedIcon,
  group: GroupsIcon,
  channel: LiveTvIcon,
  video: OndemandVideoIcon,
  event: EventIcon,
  article: NewspaperIcon,
};

export const ARTICLES_LOGO: Record<string, string> = {
  cnn: `https://d3jxof7zrsgr8z.cloudfront.net/avatar_cache/${encodeURIComponent(
    encodeURIComponent("https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/CNN_International_logo.svg/600px-CNN_International_logo.svg.png")
  )}`,
  reuters: `https://d3jxof7zrsgr8z.cloudfront.net/avatar_cache/${encodeURIComponent(
    encodeURIComponent("https://static.wikia.nocookie.net/logopedia/images/4/47/Reuters_2024_symbol.svg")
  )}`,
};

export const labels = {
  home: {
    title: "Dashboard",
    sideBarTitle: "Home",
    subtitle: "Brinker Dashboard",
  },
  monitoring: {
    title: "Monitoring",
    subtitle: "Alerts for asset detection",
    items: "Alerts",
    csv: "CSV file still in processing, try refreshing in a few moments",
    DEFAULT_VIEW: "Default",
  },
  analysis: {
    title: "Analysis",
    subtitle: "Charts and graphs",
  },
  assets: {
    title: "Assets",
    subtitle: "View and manage your assets",
    items: "Assets",
    icon: React.createElement(SecurityIcon),
  },
  flagging: {
    title: "Flagging",
    subtitle: "Suspicious content",
    items: "Flags",
  },
  investigation: {
    title: "Investigation",
    subtitle: "AI investigation of flagged content",
  },
  takeAction: {
    title: "Take Action",
    subtitle: "Generated Mitigations",
    items: "Actions",
    mitigation: "Mitigation request still in processing, try refreshing in a few moments",
    overview: "Overview file still in processing, try refreshing in a few moments",
  },
  teamManagement: {
    title: "Switch team",
    subtitle: "Manage your team",
  },
  siemIntegration: {
    title: "SIEM Integration",
    subtitle: "See events from brinker directly in your SIEM",
    splunk: [
      "1. Access your Splunk instance and navigate to Settings > Data Inputs.",
      "2. Add a new HTTP Event Collector (HEC) and note down the generated token.",
      "3. Ensure the HEC is enabled and reachable.",
      "4. Write below your HEC token and Splunk endpoint URL.",
    ],
    sentinel: [
      "1. Log in to the Azure portal and navigate to Microsoft Sentinel > Data connectors.",
      "2. Select the API/SDK connector and follow the wizard to set up a new connection.",
      "3. Obtain the API endpoint and authentication keys required for the integration.",
      "4. Write below your token and endpoint URL.",
    ],
  },
  ai: {
    title_beta: "Narratives (Beta)",
    title: "Narratives",
    subtitle: "Manage Narratives and Counter Narratives",
    items: "Narratives",
    single_item: "Narrative",
    success_count: (count: number) => `${count} Narrative(s) found`,
  },
  common: {
    indexing: "Indexing data... try refreshing in a few moments",
  },
  api: {
    title: "API",
    subtitle: "API Documentation",
  },
};

export const severity: { [key: string]: { value: number; color: string } } = {
  CRITICAL: { value: 4, color: "#ff4d4d" },
  HIGH: { value: 3, color: "#ff796f" },
  MEDIUM: { value: 2, color: "#ffa19a" },
  LOW: { value: 1, color: "#ffab56" },
};

export const engagements = [
  { engagement: "repost", titles: { original: "Original Post", related: "Reposts" } },
  { engagement: "reply", titles: { original: "Reply to", related: "Replies" } },
] as const;

export const aiFilterStatus = (aiFilter: AiFilterType) => aiFilter?.status === "failed" && aiFilter.reason;

export const engagementCount = (engagement: number) => {
  const formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
  });
  return formatter.format(engagement);
};

export const unixToLocaleDate = (date?: number) => {
  if (!date) return null;
  const dateObject = new Date(date);
  return dateObject.toLocaleString();
};

export const similarityThreshold = 0.7;
