import { Box, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useMemo, useState } from "react";
import { MentionType } from "../state";
import SeverityLineChart from "./charts/line-charts/SeverityLineChart";
import EmbeddingsScatterChart from "./charts/EmbeddingsScatterChart";
import InquiriesLineChart from "./charts/line-charts/InquiriesLineChart";
import WordCloudChart from "./charts/WordCloudChart";
import BarChart from "./charts/barCharts/BarChart";
import TopContentCreatorsPieChart from "./charts/pieCharts/TopContentCreatorsPieChart";
import NetworkGraphs from "./charts/network-graphs/NetworkGraphs";
import { GlobalSizes } from "../size";
import NarrativesPieChart from "./charts/pieCharts/NarrativesPieChart";
import NarrativesCategoryPieChart from "./charts/pieCharts/NarrativesCategoryPieChart";

const graphGroups = {
  network: ["Network (3D)", "Network (2D)", "Acyclic"],
  narratives: ["Narratives", "Categories", "Trending Narratives", "Narratives Distribution By Source", "New Narratives Clusters"],
  others: ["Word Cloud", "Top Content Creators", "Severity"],
} as const;

const allGraphs = [...graphGroups.network, ...graphGroups.narratives, ...graphGroups.others] as const;

type GraphType = (typeof allGraphs)[number];

function Graphs({ mentions }: { mentions: MentionType[] }) {
  const [selectedGraph, setSelectedGraph] = useState<GraphType>("Network (3D)");

  const alertsWithAiFilter = useMemo(() => mentions.filter((m) => m.ai_filter?.results?.length), [mentions]);

  const lineChartAlerts = useMemo(
    () => alertsWithAiFilter.filter((m) => m.creation_date && new Date(m.creation_date) >= new Date(new Date().setMonth(new Date().getMonth() - 3))),
    [alertsWithAiFilter]
  );

  const mentionsWithContent = useMemo(() => mentions.filter((m) => m.description_short).slice(0, 1000), [mentions]);

  const chartMap: Partial<Record<GraphType, JSX.Element>> = {
    Narratives: <NarrativesPieChart mentions={alertsWithAiFilter} />,
    Categories: <NarrativesCategoryPieChart mentions={alertsWithAiFilter} />,
    "Trending Narratives": <InquiriesLineChart mentions={lineChartAlerts} />,
    "Narratives Distribution By Source": <BarChart mentions={alertsWithAiFilter} />,
    "Word Cloud": <WordCloudChart mentions={mentionsWithContent} />,
    "Top Content Creators": <TopContentCreatorsPieChart mentions={mentions} />,
    Severity: <SeverityLineChart mentions={mentions} />,
    "New Narratives Clusters": <EmbeddingsScatterChart mentions={mentions} />,
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" gap={GlobalSizes.gap} p={GlobalSizes.smallGap}>
        {Object.entries(graphGroups).map(([group, graphs]) => (
          <ToggleButtonGroup
            sx={{ mb: GlobalSizes.gap }}
            key={group}
            size="small"
            value={selectedGraph}
            exclusive
            onChange={(_, val) => val && setSelectedGraph(val)}
            aria-label={group}
          >
            {graphs.map((graph) => (
              <ToggleButton key={graph} value={graph} aria-label={graph}>
                {graph}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        ))}
      </Stack>
      {graphGroups.network.includes(selectedGraph as (typeof graphGroups.network)[number]) ? (
        <NetworkGraphs mentions={mentions} graph={selectedGraph} />
      ) : (
        chartMap[selectedGraph] || null
      )}
    </Box>
  );
}

export default Graphs;
