import { SvgIcon, SvgIconProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWeibo, faVk, faWordpress, faTumblr, faQuora, faRocketchat, faSignalMessenger, faDiscord, IconDefinition } from "@fortawesome/free-brands-svg-icons";

const createFontAwesomeSvgIcon = (icon: IconDefinition) => {
  const Component = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <FontAwesomeIcon icon={icon} />
    </SvgIcon>
  );
  Component.muiName = "SvgIcon";
  return Component;
};

const createCustomSvgIcon = (path: string, viewBox = "0 0 24 24") => {
  const Component = (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox={viewBox}>
      <path d={path} />
    </SvgIcon>
  );
  Component.muiName = "SvgIcon";
  return Component;
};

export const GeminiIcon = createCustomSvgIcon(
  "M21.996 12.018a10.65 10.65 0 0 0-9.98 9.98h-.04c-.32-5.364-4.613-9.656-9.976-9.98v-.04c5.363-.32 9.656-4.613 9.98-9.976h.04c.324 5.363 4.617 9.656 9.98 9.98v.036z"
);

export const ChatGPTIcon = createCustomSvgIcon(
  "M14 2c-3 0-5.5 2-6.5 4.1C6 6.4 4.3 7.5 3.3 9.3 1.9 11.7 2.2 14.6 3.8 16.6c-.8 1.8-.7 3.9.3 5.7 1.4 2.4 4 3.6 6.7 3.3C12 27 14 28 16 28c2.7 0 5-1.7 6-4.1 2-.3 3.8-1.5 4.8-3.3 1.4-2.4 1.1-5.3-.5-7.3.8-1.8.7-3.9-.3-5.7-1.4-2.4-4-3.6-6.6-3.3C18 3 16 2 14 2zM14 4c1.1 0 2.1.4 3 1.2-.1 0-.2 0-.3.1L12.5 7.9c-.5.3-.8.8-.8 1.3v6.1l-2.1-1.2V8.5C9.6 6 11.6 4 14 4zM20.5 6.5c1.4.1 2.7.9 3.5 2.2.6 1 1 2.1.7 3.2-.1 0-.1 0-.2-.1L19.9 9.3c-.5-.3-1.1-.3-1.5 0l-5.2 3v-2.5l4.7-2.7c.8-.5 1.7-.6 2.6-.5zM7.6 8.3c0 .1 0 .2 0 .3v5.1c0 .5.3 1 .8 1.3l5.2 3-2.1 1.2-4.7-2.7C4.5 15.2 3.8 12.4 5 10.3c.6-1 1.5-1.7 2.6-2zM18.7 10.9l4.7 2.7c2.1 1.2 2.9 4 1.6 6.1-.6 1-1.5 1.7-2.6 2 .1-.1.1-.2.1-.3v-5.1c0-.5-.3-1-.8-1.3L16.5 12l2.2-1.1zM15 12.9l1.8 1.1v2l-1.8 1.1-1.8-1.1v-2l1.8-1.1zM18.3 14.9l2.1 1.2v5.4c0 2.5-2 4.5-4.5 4.5-1.2 0-2.3-.5-3.1-1.2.1 0 .2 0 .3-.1l4.4-2.6c.5-.3.8-.8.8-1.3v-5zM16.8 17.8v2.5l-4.7 2.7c-2.1 1.2-4.9.5-6.1-1.6-.6-1-1-2.1-.7-3.2.1 0 .1 0 .2.1l4.4 2.6c.5.3 1.1.3 1.5 0l5.4-3.1z"
);

export const VkIcon = createFontAwesomeSvgIcon(faVk);
export const WeiboIcon = createFontAwesomeSvgIcon(faWeibo);
export const QuoraIcon = createFontAwesomeSvgIcon(faQuora);
export const WordPressIcon = createFontAwesomeSvgIcon(faWordpress);
export const TumblrIcon = createFontAwesomeSvgIcon(faTumblr);
export const RocketChatIcon = createFontAwesomeSvgIcon(faRocketchat);
export const SignalMessengerIcon = createFontAwesomeSvgIcon(faSignalMessenger);
export const DiscordIcon = createFontAwesomeSvgIcon(faDiscord);