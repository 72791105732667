import DocsTable from "./DocsTable";
import SectionCard from "./SectionCard";
import CodeBlock from "./CodeBlock";
import { Box, Typography } from "@mui/material";
import { alertsObjProperties } from "./Alerts";

const flagResponseExample: string = `{
  "statusCode": 200,
  "alert": {
    "customer_id": "cf331f7df17fcc8f",
    "url": "https://www.linkedin.com/in/david-j-34342",
    "detection_date": 1741894549711,
    "flagged": true,
    "emails": [
      "davidjj@gmail.com",
      "davidjj91@gmail.com",
    ],
    "phone_numbers": [
      "010 09433344",
      "011 98005968"
    ],
    "education": [
      {
        "degree": "Doctor of Philosophy - PhD, Electrical and Electronics Engineering",
        "school": "University of Southern California",
        "date_range": {
          "start": "2019-01-01",
          "end": "2024-12-31"
        },
        "display": "Doctor of Philosophy - PhD, Electrical and Electronics Engineering from University of Southern California (2019-2024)"
      }
    ],
    "work_history": [
      {
        "organization": "James Research Scholars Program",
        "title": "Research Scholar",
        "display": "Research Scholar at James Research Scholars Program (since 2023)",
        "date_range": {
          "start": "2023-02-01"
        }
      },
      {
        "organization": "Analog Devices",
        "title": "RF IC Designer & Researcher",
        "display": "RF IC Designer & Researcher at Analog Devices (2016-2018)",
        "date_range": {
          "start": "2016-08-01",
          "end": "2018-02-01"
        }
      },
    ],
    "location_history": [
      "Los Angeles, CA"
    ],
    "gender": "male"
  }
}`;

const flagQueryParams = [{ "Property Key": "url", "Value Type": "String", Description: "The profile URL to search." }];

const flagRequestBodyExample = `{
 "url": "https://www.linkedin.com/in/david-j/"
}`;

const FlaggingDocs = () => {
  return (
    <SectionCard title="Flagging">
      <Typography variant="body1">
        <Box sx={{ fontWeight: "bold" }}>
          <u>Method:</u> <span style={{ color: "#eb9234", fontWeight: "700" }}>POST</span>
        </Box>
        <Box sx={{ fontWeight: "bold" }}>
          <u>Path:</u> /flag
        </Box>
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Body
      </Typography>
      <DocsTable data={flagQueryParams} />
      <br />
      <Typography variant="h6" gutterBottom>
        Request Body Example:
      </Typography>
      <CodeBlock code={flagRequestBodyExample} />
      <br />
      <Typography variant="h6">Response Body Example:</Typography>
      <CodeBlock code={flagResponseExample} />
      <br />
      <Typography variant="h5" gutterBottom>
        Object Properties (Response)
      </Typography>
      <DocsTable data={alertsObjProperties} />
    </SectionCard>
  );
};

export default FlaggingDocs;
