import { Autocomplete, Box, FormControl, TextField } from "@mui/material";
import { labels } from "../../labels";
import { AIQuestionType } from "../../state";
import { GlobalSizes } from "../../size";
import { useMemo } from "react";
import { useWsContext } from "../../ws-context";

interface NarrativeFieldsFormProps {
  index?: number;
  localQuestions: AIQuestionType[];
  setLocalQuestions: React.Dispatch<React.SetStateAction<AIQuestionType[]>>;
  setNewQuestion?: React.Dispatch<React.SetStateAction<AIQuestionType>>;
  question: AIQuestionType;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
}

const NarrativeFieldsForm = (props: NarrativeFieldsFormProps) => {
  const { index, localQuestions, setLocalQuestions, setNewQuestion, question, error, setError } = props;
  const { dashboardState } = useWsContext();

  const handleInputChange = (field: string, value: string, index?: number) => {
    if (index !== undefined) {
      setLocalQuestions((prevQuestions) => {
        const updatedQuestions = [...prevQuestions];
        updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
        return updatedQuestions;
      });
    } else {
      setNewQuestion!((prev) => ({ ...prev, [field]: value }));
    }

    if (field === "label") {
      setError(localQuestions.some((question) => question.label.toLowerCase() === value.toLowerCase()));
    }
  };

  const categories = useMemo(() => {
    return Array.from(new Set((dashboardState.ai_questions || []).map((q) => q.category).filter(Boolean)));
  }, [dashboardState.ai_questions]);

  return (
    <Box mt={GlobalSizes.gap}>
      <TextField
        placeholder={`Enter ${labels.ai.single_item} Label`}
        value={question.label}
        name="label"
        variant="outlined"
        size="small"
        fullWidth
        error={error}
        helperText={error ? "This narrative already exists" : ""}
        onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
      />
      <TextField
        placeholder={`Enter ${labels.ai.single_item}`}
        value={question.question}
        name="question"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
        sx={{ mt: GlobalSizes.smallGap }}
      />
      <TextField
        placeholder="Enter Mitigating content"
        value={question.mitigation}
        name="mitigation"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
        sx={{ mt: GlobalSizes.smallGap }}
      />
      <TextField
        placeholder="Enter Link"
        value={question.link}
        name="link"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        onChange={(e) => handleInputChange(e.target.name, e.target.value, index)}
        sx={{ mt: GlobalSizes.smallGap }}
      />
      <FormControl fullWidth sx={{ mb: GlobalSizes.gap }}>
        <Autocomplete
          freeSolo
          disablePortal
          id="combo-box-demo"
          options={categories}
          renderInput={(params) => <TextField {...params} placeholder="Enter Category" />}
          value={question.category}
          onInputChange={(_, value) => handleInputChange("category", value, index)}
          sx={{ mt: GlobalSizes.smallGap }}
        />
      </FormControl>
    </Box>
  );
};

export default NarrativeFieldsForm;
