import { Accordion, AccordionDetails, AccordionSummary, Box, List, Stack, Typography } from "@mui/material";
import { MentionType } from "../state";
import { Link } from "react-router-dom";
import { GlobalSizes } from "../size";
import MentionListItem from "./MentionListItem";
import { useMemo } from "react";
import AlertsCounter from "./AlertsCounter";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { engagements, severity } from "../labels";

const EngagementSection = ({ title, relatedPosts }: { title: string; relatedPosts: MentionType[] }) => (
  <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Stack direction="row" alignItems="center" width={GlobalSizes.fullSize}>
        <Typography variant="h4" sx={{ flexGrow: GlobalSizes.smallGap }}>
          {title}
        </Typography>
        <Box mr={GlobalSizes.gap}>
          <AlertsCounter alerts={relatedPosts} />
        </Box>
      </Stack>
    </AccordionSummary>
    <AccordionDetails>
      <List>
        {relatedPosts.map((post) => (
          <MentionListItem key={post.url} alert={post} />
        ))}
      </List>
    </AccordionDetails>
  </Accordion>
);

const sortBySeverity = (a: MentionType, b: MentionType) => {
  const aSeverity = a.manual_severity?.toUpperCase() ?? a.severity?.toUpperCase() ?? "MEDIUM";
  const bSeverity = b.manual_severity?.toUpperCase() ?? b.severity?.toUpperCase() ?? "MEDIUM";
  const severityComparison = (severity[bSeverity]?.value ?? 0) - (severity[aSeverity]?.value ?? 0);
  if (!severityComparison) {
    return a.hidden === b.hidden ? 0 : a.hidden ? 1 : -1; // hidden posts should be at the end
  }
  return severityComparison;
};

const Engagements = ({ alert, allMentions }: { alert: MentionType; allMentions: MentionType[] }) => {
  const relevantMentions = useMemo(() => allMentions.filter(({ source }) => source === alert.source), [allMentions, alert.source]);

  return (
    <Box mt={GlobalSizes.gap}>
      {engagements.map(({ engagement, titles }) => {
        const originalPost = relevantMentions.find(({ url }) => url === alert[engagement]);
        const relatedPosts = relevantMentions.filter(({ [engagement]: engagementUrl }) => engagementUrl === alert.url).sort(sortBySeverity);
        return originalPost || relatedPosts.length || alert[engagement] ? (
          <Box key={engagement}>
            {(originalPost || alert[engagement]) && (
              <Box>
                <Typography variant="h4">{titles.original}</Typography>
                {originalPost ? (
                  <MentionListItem alert={originalPost} />
                ) : (
                  <Link to={alert[engagement]!} target="_blank">
                    {alert[engagement]}
                  </Link>
                )}
              </Box>
            )}
            {relatedPosts.length > 0 && <EngagementSection title={titles.related} relatedPosts={relatedPosts} />}
          </Box>
        ) : null;
      })}
    </Box>
  );
};

export default Engagements;
