
import { Box, useMediaQuery } from "@mui/material";

const CodeBlock = ({ code }: { code: string }) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const boxColor = prefersDarkMode ? "#1f1f1f" : "#efefef";
  
    return (
      <Box
        component="pre"
        sx={{
          backgroundColor: boxColor,
          padding: 2,
          borderRadius: 2,
          whiteSpace: "pre-wrap",
        }}
      >
        {code}
      </Box>
    );
  };

export default CodeBlock;