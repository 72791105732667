import { useEffect, useState } from "react";
import { AIQuestionType, SeverityType } from "../../state";
import { useWsContext } from "../../ws-context";
import SeverityButton from "../SeverityButton";

function NarrativeSeverity({ narrative, index }: { narrative: AIQuestionType; index: number }) {
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityType>(narrative?.manual_severity || "medium");

  const { updateDashboard, emitNewData, dashboardState } = useWsContext();

  const handleSeverityChange = (severity: SeverityType) => {
    setSelectedSeverity(severity);
    const updatedQuestions = dashboardState.ai_questions!.map((question, i) => (i === index ? { ...question, manual_severity: severity } : question));
    updateDashboard({ ai_questions: updatedQuestions });
    emitNewData({
      action: "setAIQuestion",
      questionIndex: index.toString(),
      question: updatedQuestions[index],
    });
  };

  useEffect(() => {
    setSelectedSeverity(narrative?.manual_severity || "medium");
  }, [narrative?.manual_severity]);

  return (
    <SeverityButton selectedSeverity={selectedSeverity} onSeverityChange={handleSeverityChange} showIcon={!!narrative?.manual_severity} />
  );
}

export default NarrativeSeverity;
