import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GlobalSizes } from "../size";
import ConnectedUsers from "./ConnectedUsers";
import { useWsContext } from "../ws-context";

function Bar({ label }: { label: string }) {
  const { dashboardState } = useWsContext();
  return (

    <Box sx={{ flexGrow: GlobalSizes.flex }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: GlobalSizes.flex }}>
            {label}
          </Typography>
          {dashboardState.web_socket_connections && <ConnectedUsers />}
          <Button href="https://intercom.help/brinker" color="inherit">
            Help
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Bar;
