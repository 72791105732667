import { LineChart } from "@mui/x-charts";
import { Box, Typography } from "@mui/material";
import { GlobalSizes } from "../../../size";
import CustomLegend from "../CustomLegend";
import { severity } from "../../../labels";

interface LineChartViewProps {
  title: string;
  showLegend?: boolean;
  countsByDate: { [key: string]: number[] };
  dates: number[];
  stringToColor?: (str: string) => string;
}

const LineChartView = ({ title, showLegend, countsByDate, dates, stringToColor }: LineChartViewProps) => {
  const series = Object.entries(countsByDate).map(([key, data]) => ({
    id: key,
    data,
    label: key,
    stack: "total",
    area: true,
    color: stringToColor ? stringToColor(key) : severity[key].color,
    showMark: false,
  }));

  return (
    <Box width={GlobalSizes.cards.width} height={GlobalSizes.fullScreenHeight}>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        {title}
      </Typography>
      {showLegend && <CustomLegend labels={series.map((s) => ({ name: s.label, color: s.color }))} />}
      <LineChart
        slotProps={{ legend: { hidden: true } }}
        xAxis={[
          {
            id: "Days",
            dataKey: "date",
            scaleType: "time",
            data: dates,
            valueFormatter: (date) => new Date(date).toLocaleDateString(),
          },
        ]}
        series={series}
        height={GlobalSizes.chartHeight}
        skipAnimation
      />
    </Box>
  );
};

export default LineChartView;
