import { useEffect, useState } from "react";
import { MentionType, SeverityType } from "../state";
import { useWsContext } from "../ws-context";
import SeverityButton from "./SeverityButton";

function MentionSeverity({ alert }: { alert?: MentionType }) {
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityType>(alert?.manual_severity || alert?.severity || "medium");

  const { updateAlerts, emitNewData } = useWsContext();

  let tooltip = "Severity automatically set by the system";

  if (alert?.manual_severity) {
    tooltip = "Severity manually set by user to train the system";
  }

  const handleSeverityChange = (severity: SeverityType) => {
    if (!alert) return;
    setSelectedSeverity(severity);
    updateAlerts({ ...alert, manual_severity: severity });
    emitNewData({ action: "updateAlert", mention: { url: alert.url, customer_id: alert.customer_id, manual_severity: severity } });
  };

  useEffect(() => {
    setSelectedSeverity(alert?.manual_severity || alert?.severity || "medium");
  }, [alert?.manual_severity, alert?.severity]);

  return <SeverityButton selectedSeverity={selectedSeverity} onSeverityChange={handleSeverityChange} tooltip={tooltip} showIcon={!!alert?.manual_severity} />;
}

export default MentionSeverity;
