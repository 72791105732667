import { useMemo } from "react";
import { MentionType } from "../../../state";
import LineChartView from "./LineChartView";
import { useClientContext } from "../../../client-context";

function InquiriesLineChart({ mentions }: { mentions: MentionType[] }) {
  const { stringToColor } = useClientContext();
  const { dates, countsByDate } = useMemo(() => {
    const countsByDate: { [key: string]: number[] } = {};

    const dates = Array.from(new Set(mentions.map((mention) => new Date(mention.creation_date!).setHours(0, 0, 0, 0)).filter((date) => date !== null))).sort();

    mentions.forEach((mention) => {
      const dateIndex = dates.indexOf(new Date(mention.creation_date!).setHours(0, 0, 0, 0));
      mention.ai_filter?.results?.forEach((result) => {
        if (!countsByDate[result.key]) {
          countsByDate[result.key] = new Array(dates.length).fill(0);
        }
        countsByDate[result.key][dateIndex]++;
      });
    });

    return { dates, countsByDate };
  }, [mentions]);

  return <LineChartView title={`Trending Narratives (${mentions.length} alerts)`} countsByDate={countsByDate} dates={dates} stringToColor={stringToColor} />;
}

export default InquiriesLineChart;
