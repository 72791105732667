import DocsTable from "./DocsTable";
import SectionCard from "./SectionCard";
import CodeBlock from "./CodeBlock";
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const eventsMapping: { event_name: string; description: string }[] = [
  {
    event_name: "login",
    description: "Successful login event.",
  },
  {
    event_name: "signup",
    description: "Successful Signup",
  },
  {
    event_name: "new_narrative",
    description: "A new narrative was created by the user.",
  },
  {
    event_name: "delete_narrative",
    description: "An existing narrative was removed by the user.",
  },
  {
    event_name: "edit_narrative",
    description: "An existing narrative was edited or updated by the user.",
  },
  {
    event_name: "new_asset",
    description: "A new asset was added.",
  },
  {
    event_name: "remove_asset",
    description: "An existing asset was removed.",
  },
  {
    event_name: "new_user",
    description: "A new user was added to the team.",
  },
  {
    event_name: "delete_user",
    description: "A user account was removed from the team.",
  },
  {
    event_name: "teamName_change",
    description: "The team name was updated.",
  },
];

const auditResponseExample: string = `{
  "customer_id": "abcde12345",
  "count": 4,
  "events": [
    {
      "event_name": "new_narrative",
      "event_description": "New Narrative",
      "user_name": "John Doe",
      "date": "2025-02-24T15:30:00Z",
      "timestamp": 1582605070000,
      "data": "Does the text criticize..."
    },
    {
      "event_name": "delete_narrative",
      "event_description": "Delete Narrative",
      "user_name": "Jane Doe",
      "date": "2025-02-02T07:45:28",
      "timestamp": 1582605270000,
      "data": "Does the text express..."
    },
    {
      "event_name": "login",
      "event_description": "Successful Authentication",
      "user_name": "Alice Smith",
      "client_ip": "192.168.1.1",
      "date": "2025-02-02T07:45:28",
      "timestamp": 1738482331790,
      "user_agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/132.0.0.0 Safari/537.36",
      "log_id": "9002025020207523170000000000000001423373063878871122"
    },
    {
      "event_name": "signup",
      "event_description": "Successful Signup",
      "user_name": "Bob Brown",
      "client_ip": "191.163.1.1",
      "date": "2025-01-24T17:00:00Z",
      "timestamp": 1582673970000,
      "user_agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/",
      "log_id": "900202502020752317000000000000000142337
    }
  ]
}`;

const auditQueryParams: [string, string, string, string][] = [
  ["from_date", "String", "Start date for audit logs (YYYY-MM-DD).", "2024-12-31"],
  ["to_date", "String", "End date for audit logs (YYYY-MM-DD).", "2025-02-01"],
  ["events", "String", "Comma-separated list of events to retrieve. Defaults to all events.", "login, signup, new_narrative, new_asset"],
];

const clickEventProperties: { "Property Key": string; "Value Type": string; Description: string }[] = [
  {
    "Property Key": "event_name",
    "Value Type": "String",
    Description: "The specific interaction event (e.g., new_narrative, delete_narrative, edit_narrative, etc.).",
  },
  { "Property Key": "event_description", "Value Type": "String", Description: "A description of the interaction event." },
  { "Property Key": "user_name", "Value Type": "String", Description: "The name of the user triggering the event." },
  { "Property Key": "date", "Value Type": "String", Description: "The date when the event occurred." },
  { "Property Key": "timestamp", "Value Type": "Integer", Description: "The exact timestamp of the event in milliseconds." },
  { "Property Key": "data", "Value Type": "string", Description: "Additional data specific to the interaction event." },
];

const authenticationEventProperties: { "Property Key": string; "Value Type": string; Description: string }[] = [
  { "Property Key": "event_name", "Value Type": "String", Description: "The name of the event (e.g., login, signup)." },
  { "Property Key": "event_description", "Value Type": "String", Description: "A brief description of the event." },
  { "Property Key": "user_name", "Value Type": "String", Description: "The name of the user who triggered the event." },
  { "Property Key": "client_ip", "Value Type": "String", Description: "The IP address of the client initiating the event." },
  { "Property Key": "date", "Value Type": "String", Description: "The date when the event occurred." },
  { "Property Key": "timestamp", "Value Type": "Integer", Description: "The exact timestamp of the event in milliseconds." },
  { "Property Key": "user_agent", "Value Type": "String", Description: "The user agent string of the client." },
  { "Property Key": "log_id", "Value Type": "String", Description: "A unique identifier for the event log." },
];

const QueryParamsTable = ({ params, keys }: { params: [string, string, string, string][]; keys?: string[] }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          {keys?.map((key) => (
            <TableCell key={key}>
              <strong>{key}</strong>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {params.map(([key, type, description, example]) => (
          <TableRow key={key}>
            <TableCell>{key}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell>
              {description} {(key === "from_date" || key === "to_date") && <em style={{ color: "red" }}> (Required)</em>}
            </TableCell>
            <TableCell>{example}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const Alerts = () => {
  return (
    <Box>
      <SectionCard title="Audit Logs">
        <Typography variant="body1">
          <Box fontWeight={"bold"}>
            <u>Method:</u> <span style={{ color: "#3deb34", fontWeight: "700" }}>GET</span>
          </Box>
          <Box fontWeight={"bold"}>
            <u>Path:</u> /audit
          </Box>
        </Typography>
        <br />
        <Typography variant="h6">Query Parameters:</Typography>
        <QueryParamsTable params={auditQueryParams} keys={["Key", "Type", "Description", "Example"]} />
        <br />
        <Typography variant="h6">Events:</Typography>
        <DocsTable data={eventsMapping} />
        <br />
      </SectionCard>
      <SectionCard title="Audit Logs (Open Application and User Interaction Events)">
        <Typography variant="body1">
          The /audit endpoint returns a mix of open application events and interaction events. For open application events ("s" and "ss"), you will see details
          like user name, client IP, user agent, and log ID. For user interaction events, the response will include additional data.
        </Typography>
        <br />
        <Typography variant="h6" gutterBottom>
          User Interaction Event Properties
        </Typography>
        <DocsTable data={clickEventProperties} />
        <br />
        <Typography variant="h6" gutterBottom>
          Open application Event Properties:
        </Typography>
        <DocsTable data={authenticationEventProperties} />
        <br />
        <Typography variant="h6">Sample Response:</Typography>
        <CodeBlock code={auditResponseExample} />
      </SectionCard>
    </Box>
  );
};

export default Alerts;
