import { MentionType } from "../state";
import { Stack, Container, Button, Typography, Avatar, Badge, Box, Tooltip, SelectChangeEvent, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { labels, SOCIAL_INFO } from "../labels";
import { useCallback, useEffect, useState, useMemo, useRef } from "react";
import { GlobalSizes } from "../size";
import {
  DataGridPro,
  GridRenderCellParams,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridInitialState,
  GridListColDef,
  gridFilteredSortedRowEntriesSelector,
  GridCellParams,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import List from "@mui/icons-material/List";
import GridOnIcon from "@mui/icons-material/GridOn";
import PersonIcon from "@mui/icons-material/Person";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { Link } from "react-router-dom";
import CustomTableViews from "../Components/monitoring/CustomTableViews";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import MentionSeverity from "../Components/MentionSeverity";
import LinearProgress from "@mui/material/LinearProgress";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import { getViews, setViews } from "../team-util";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";
import MentionFocus from "../Components/MentionFocus";
import debounce from "lodash.debounce";
import ConnectedUsers from "../Components/ConnectedUsers";
import { useColumns } from "../useColumns";
import ImportDialog from "../Components/monitoring/ImportDialog";
import FullScreenChartsDialog from "../Components/FullScreenChartsDialog";
import { similarityThreshold } from "../labels";
import AssetHighlighter from "../Components/assets/AssetHighlighter";

export const initialGridState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      refresh_ai_filter: false,
      ai_filter_beta: false,
      takedown_status: false,
      distribution: false,
      video_transcription: false,
    },
  },
  filter: {
    filterModel: {
      items: [{ field: "status", operator: "not", value: "hidden" }],
    },
  },
  sorting: {
    sortModel: [{ field: "detection_date", sort: "desc" }],
  },
  pagination: {
    paginationModel: { pageSize: 100, page: 0 },
  },
  density: "standard",
};

function LinearLoadingOverlay() {
  const { dashboardState, isLoadingMentions } = useWsContext();
  return <div style={{ width: "100%", height: "5px" }}>{!dashboardState.customer_id || isLoadingMentions ? <LinearProgress /> : null}</div>;
}

function Monitoring() {
  const { mentionsState, dashboardState, updateAlerts, isLoadingMentions, sidebarAlert, setSidebarAlert } = useWsContext();
  const { tempView, setTempView } = useClientContext();
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [currentView, setCurrentView] = useState("");
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [displayedAlerts, setDisplayedAlerts] = useState(0);
  const [isListView, setIsListView] = useState(false);
  const [showFullScreenCharts, setShowFullScreenCharts] = useState(false);
  const [filteredMentions, setFilteredMentions] = useState<MentionType[]>([]);

  const { columns, listViewColumns, aiFilterRenderCell } = useColumns(isListView);

  const apiRef = useGridApiRef();

  const firstLoadRef = useRef(true);

  const [nextAlerts, setNextAlerts] = useState<MentionType[]>([]);

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    if (!dashboardState.customer_id) return;
    setCurrentView(event.target.value);
    const allViews = getViews();
    const chosenView = dashboardState.saved_views?.[event.target.value];
    if (!chosenView) return;
    apiRef.current.restoreState(chosenView);
    setViews({ ...allViews, [dashboardState.customer_id]: event.target.value });
    setTempView(chosenView);
  };

  function readUnread(mention: MentionType) {
    return mention.read || mention.flagged || mention.hidden || mention.whitelisted || !!mention.manual_severity;
  }

  const ListViewCell = useCallback(
    (params: GridRenderCellParams) => {
      const creationDate = new Date(params.row.creation_date);
      const SelectedIcon = SOCIAL_INFO[params.row.source]?.icon || PersonIcon;
      const shortenedFullContent = params.row.full_content?.replace(/\s*\n\s*/g, " ").trim();
      const shortenedDescription = params.row.description_short?.replace(/\s*\n\s*/g, " ").trim();
      return (
        <Stack
          onClick={() => {
            updateAlerts({ ...params.row, read: true });
            setSidebarAlert(params.row);
          }}
          direction="row"
          spacing={GlobalSizes.gap}
          alignItems="flex-start"
          sx={{
            padding: GlobalSizes.gap,
            width: GlobalSizes.fullSize,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={<SelectedIcon color="disabled" fontSize="small" />}
          >
            <Avatar sx={{ width: 54, height: 54 }} alt={params.row.user} src={params.row.avatar} />
          </Badge>
          <Stack flexGrow={GlobalSizes.smallGap} gap={GlobalSizes.smallGap}>
            <Typography variant="subtitle2" fontWeight="bold" color="text.primary" component="span">
              {params.row.user}
              <Link to={params.row.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                <Tooltip title={params.row.url} placement="top">
                  <Typography component="span" variant="caption" color="text.secondary">
                    {" • " + (params.row.source || params.row.url)}
                  </Typography>
                </Tooltip>
              </Link>
              {params.row.creation_date && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {params.row.source && " • "}
                  {creationDate.toLocaleDateString(undefined, { month: "short", day: "numeric" })}
                </Typography>
              )}
              {params.row.severity && (
                <Typography component="span" variant="caption" color="text.secondary">
                  {" • "}
                  <MentionSeverity alert={params.row} />
                </Typography>
              )}
              {params.row.ai_filter && <Box my={1}>{aiFilterRenderCell(params.row.ai_filter, params)}</Box>}
            </Typography>
            {shortenedDescription && (
              <Typography variant={params.row.type !== "article" ? "body2" : "body1"} color="text.primary" sx={{ mb: GlobalSizes.smallGap }}>
                <AssetHighlighter text={shortenedDescription} asset={params.row.asset} />
                ...
              </Typography>
            )}
            {shortenedFullContent && (
              <Typography variant="body2" color="text.secondary">
                <AssetHighlighter text={shortenedFullContent} asset={params.row.asset} />
                ...
              </Typography>
            )}
          </Stack>
        </Stack>
      );
    },
    [aiFilterRenderCell, updateAlerts, setSidebarAlert]
  );

  const listColDef: GridListColDef = useMemo(() => {
    return {
      field: "listColumn",
      renderCell: ListViewCell,
    };
  }, [ListViewCell]);

  const restoreStateToDefault = useCallback(() => {
    apiRef.current.restoreState(initialGridState);
  }, [apiRef]);

  const debouncedFilterModelListener = useMemo(
    () =>
      debounce(() => {
        if (!isListView && apiRef.current) {
          const { preferencePanel, ...restGridAttributes } = apiRef.current?.exportState();
          setTempView(restGridAttributes);
        }
      }, 300),
    [apiRef, setTempView, isListView]
  );

  const debouncedTableListener = useMemo(() => debounce(() => setTempView(apiRef?.current?.exportState()), 300), [apiRef, setTempView]);

  useEffect(() => {
    setFilteredMentions((gridFilteredSortedRowEntriesSelector(apiRef).map((entry) => entry.model) as MentionType[]) || []);

    const restoreViewState = (view?: GridInitialState) => {
      if (tempView?.columns) {
        apiRef.current.restoreState(tempView);
      } else if (view) {
        const mergedColumnVisibilityModel: Record<string, boolean> = {
          ...(initialGridState?.columns?.columnVisibilityModel || {}),
          ...(view?.columns?.columnVisibilityModel || {}),
        };

        apiRef.current.restoreState({
          ...view,
          columns: {
            ...view.columns,
            columnVisibilityModel: mergedColumnVisibilityModel,
          },
        });

        setTempView({ ...view, columns: { ...view.columns, columnVisibilityModel: mergedColumnVisibilityModel } });
      } else {
        restoreStateToDefault();
      }
    };

    const updateCurrentView = () => {
      const savedViews = dashboardState.saved_views || {};
      const savedView = getViews()[dashboardState.customer_id!];
      const firstSavedView = Object.keys(savedViews)[0];

      const viewToSet = savedView || firstSavedView || labels.monitoring.DEFAULT_VIEW;
      setCurrentView(viewToSet);

      if (dashboardState.customer_id && !isListView && (firstLoadRef.current || !isLoadingMentions)) {
        if (firstLoadRef.current) firstLoadRef.current = false;
        restoreViewState(savedViews[viewToSet] || undefined);
      }
    };

    updateCurrentView();
  }, [dashboardState.customer_id, dashboardState.saved_views, isListView, isLoadingMentions, apiRef, restoreStateToDefault, tempView, setTempView]);

  useEffect(() => {
    const { orderedFields, dimensions } = tempView?.columns || {};
    if (!orderedFields || !dimensions) return;
    apiRef.current.restoreState({ columns: { orderedFields, dimensions } });
  }, [tempView, apiRef, columns]);

  useEffect(() => {
    if (sidebarAlert) {
      apiRef.current.setRowSelectionModel([sidebarAlert.url]);
    }
  }, [apiRef, sidebarAlert]);

  const mentionsWithSimilarity = useMemo(
    () => filteredMentions.filter((m) => (m.similar?.[0]?.score || 0) > similarityThreshold).slice(0, 1000),
    [filteredMentions]
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ToggleButtonGroup
          size="small"
          value={isListView}
          exclusive
          onChange={(event: React.MouseEvent<HTMLElement>, isListView: boolean) => {
            console.log({ event, isListView });
            if (!isListView) {
              if (dashboardState.saved_views?.[currentView]) {
                apiRef.current.restoreState(dashboardState.saved_views[currentView]);
              }
            }
            setIsListView(isListView);
          }}
          aria-label="view type"
        >
          <Tooltip title="Table View">
            <ToggleButton value={false} aria-label="Table View">
              <GridOnIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="List View">
            <ToggleButton value={true} aria-label="List View">
              <List />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Typography fontWeight={"bold"}>Monitoring</Typography>
        {`| Showing ${displayedAlerts} of ${mentionsState.length} Total Alerts`}
        <Tooltip title="Customize filter and column views" placement="top">
          <Button startIcon={<ViewCompactIcon />} onClick={() => setIsViewDialogOpen(true)}>
            {currentView && currentView !== labels.monitoring.DEFAULT_VIEW
              ? currentView
              : !Object.keys(dashboardState.saved_views || {}).length && labels.monitoring.DEFAULT_VIEW}
          </Button>
        </Tooltip>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {mentionsWithSimilarity.length > 1 && (
          <Button onClick={() => setShowFullScreenCharts((prev) => !prev)}>
            <AutoGraphIcon sx={{ margin: "0px", marginRight: "4px" }} />
            Charts
          </Button>
        )}
        <Box sx={{ flexGrow: GlobalSizes.smallGap }} />
        <GridToolbarExport />
        <Button size="small" component="label" startIcon={<FileUploadOutlinedIcon fontSize="small" />} onClick={() => setIsImportDialogOpen(true)}>
          Upload
        </Button>
        {dashboardState.web_socket_connections && <ConnectedUsers />}
      </GridToolbarContainer>
    );
  }

  const handleKeyDown = (params: GridCellParams, event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleRowSelection(params.row);
    }
  };

  const handleRowSelection = (row: MentionType) => {
    setSidebarAlert(row);
    updateAlerts({ ...row, read: true });
    const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef).map((entry) => entry.model) as MentionType[];
    setNextAlerts(filteredRows);
  };

  //!JSX
  return (
    <div>
      <Container
        sx={{
          height: "calc(100vh - 80px)",
          maxHeight: "calc(100vh - 80px)",
          minHeight: "calc(100vh - 80px)",
          minWidth: "calc(100vw - 290px)",
          maxWidth: "calc(100vw - 290px)",
          width: "calc(100vw - 290px)",
          marginTop: 2,
          marginLeft: 0,
        }}
      >
        {isViewDialogOpen && (
          <CustomTableViews
            openDialog={isViewDialogOpen}
            currentView={currentView}
            setCurrentView={setCurrentView}
            gridState={apiRef.current.exportState()}
            handleCloseDialog={() => {
              setIsViewDialogOpen(false);
            }}
            handleViewChange={handleViewChange}
            restoreStateToDefault={restoreStateToDefault}
          />
        )}
        {isImportDialogOpen && <ImportDialog isImportDialogOpen={isImportDialogOpen} setIsImportDialogOpen={setIsImportDialogOpen} />}
        <LinearLoadingOverlay />
        <DataGridPro
          getRowClassName={(params) => (readUnread(params.row) ? "read-row" : "")}
          sx={{
            border: 0,
            "& .MuiDataGrid-row": {
              border: "1px solid #00000000",
              fontWeight: "500",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#00000000",
                border: "1px solid #ddd",
                boxShadow: "0 0 10px 0 #aaaaaa99",
              },
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "#6c73f044",
              "&:hover": {
                backgroundColor: "#6c73f044",
              },
            },
            "& .read-row": {
              backgroundColor: "#6c73f022",
              fontWeight: "Light",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "#6c73f022",
                border: "1px solid #aaa",
                boxShadow: "0 0 10px 0 #aaaaaa99",
              },
            },
          }}
          onRowClick={(params: GridRowParams<MentionType>) => {
            handleRowSelection(params.row);
          }}
          onCellKeyDown={handleKeyDown}
          onColumnVisibilityModelChange={debouncedTableListener}
          onDensityChange={debouncedTableListener}
          onFilterModelChange={debouncedFilterModelListener}
          onSortModelChange={debouncedTableListener}
          onPaginationModelChange={debouncedTableListener}
          onColumnWidthChange={debouncedTableListener}
          onColumnOrderChange={debouncedTableListener}
          onPinnedColumnsChange={debouncedTableListener}
          onStateChange={() => {
            setDisplayedAlerts(mentionsState.length > 0 && apiRef.current.exportState() ? apiRef.current.exportState().pagination?.rowCount ?? 0 : 0);
          }}
          headerFilters
          slots={{ toolbar: CustomToolbar }}
          rows={mentionsState}
          rowHeight={isListView ? 220 : undefined}
          columns={isListView ? listViewColumns : columns}
          unstable_listView={isListView}
          unstable_listColumn={listColDef}
          apiRef={apiRef}
          getRowId={(row) => row.url}
          pageSizeOptions={[10, 20, 50, 100, 500, 1000]}
          pagination
        />
      </Container>
      <FullScreenChartsDialog
        filteredMentions={mentionsWithSimilarity}
        showFullScreenCharts={showFullScreenCharts}
        setShowFullScreenCharts={setShowFullScreenCharts}
      />
      <MentionFocus nextAlerts={nextAlerts} setNextAlerts={setNextAlerts} />
    </div>
  );
}

export default Monitoring;
