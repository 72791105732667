import { useMemo } from "react";
import { AIQuestionType, MentionType } from "../../../state";
import PieChartView from "./PieChartView";
import { useClientContext } from "../../../client-context";
import { useWsContext } from "../../../ws-context";

const accumulateCategories = (aiQuestions: AIQuestionType[], mentions: MentionType[]) => {
  const labelToCategory = new Map(aiQuestions.filter((question) => question.category).map((question) => [question.label, question.category]));
  const categoryCount = new Map<string, number>();

  mentions.forEach((m) =>
    m.ai_filter?.results?.forEach((result) => {
      const category = labelToCategory.get(result.key);
      if (category) categoryCount.set(category, (categoryCount.get(category) || 0) + 1);
    })
  );

  return Array.from(categoryCount).map(([id, value]) => ({ id, value }));
};

function NarrativesCategoryPieChart({ mentions }: { mentions: MentionType[] }) {
  const { dashboardState } = useWsContext();
  const { categoryColorMap } = useClientContext();
  const pieData = useMemo(() => {
    const aiQuestions = dashboardState.ai_questions ?? [];
    return accumulateCategories(aiQuestions, mentions).map(({ id, value }) => ({
      id,
      value,
      label: id,
      color: categoryColorMap[id] || "#808080",
    }));
  }, [dashboardState.ai_questions, categoryColorMap, mentions]);
  return <PieChartView title="Top 10 Categories" data={pieData} />;
}

export default NarrativesCategoryPieChart;
