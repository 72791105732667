import { Container, Tabs, Tab, Typography, Checkbox, Box, Tooltip } from "@mui/material";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { useEffect, useMemo, useState } from "react";
import { useWsContext } from "../ws-context";
import { useClientContext } from "../client-context";
import Bar from "../Components/Bar";
import { labels } from "../labels";
import { useLocation, useNavigate } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuth0 } from "@auth0/auth0-react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import { WordCloudController, WordElement } from "chartjs-chart-wordcloud";

ChartJS.register(...registerables, WordCloudController, WordElement);

function Analysis() {
  const { analysisCurrentView, setAnalysisCurrentView } = useClientContext();
  const { dashboardState, emitNewData } = useWsContext();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { user } = useAuth0();

  const nav = useNavigate();
  const location = useLocation();

  const reports = useMemo(
    () =>
      Object.values(dashboardState.reports || {})
        .map((report) => report.saved_view)
        .sort((a, b) => a.localeCompare(b)),
    [dashboardState.reports]
  );

  const hasReports = reports.length > 0;

  const handleViewChange = (_event: React.ChangeEvent<{}>, newValue: string) => {
    setAnalysisCurrentView(newValue);
    nav(`/analysis?report=${encodeURIComponent(newValue)}`, { replace: true });
  };

  const reportId = useMemo(() => {
    const entry = Object.entries(dashboardState.reports || {}).find(([_, report]) => report.saved_view === analysisCurrentView);
    return entry && entry[0] !== analysisCurrentView ? entry[0] : undefined;
  }, [dashboardState.reports, analysisCurrentView]);

  const handleSubscriptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const subscribed = event.target.checked;
    setIsSubscribed(subscribed);

    if (subscribed) {
      emitNewData({
        action: "setReport",
        reportId,
        saved_view: analysisCurrentView,
      });
    } else {
      emitNewData({
        action: "removeReport",
        reportId,
      });
    }
  };

  const report = dashboardState.reports?.[reportId || 0];

  useEffect(() => {
    const viewFromUrl = new URLSearchParams(location.search).get("report");
    if (viewFromUrl && reports.includes(viewFromUrl) && viewFromUrl !== analysisCurrentView) {
      setAnalysisCurrentView(decodeURIComponent(viewFromUrl));
    } else if (!viewFromUrl && !analysisCurrentView && reports.length) {
      setAnalysisCurrentView(reports[0]);
    } else if (analysisCurrentView && viewFromUrl !== analysisCurrentView) {
      nav(`/analysis?report=${encodeURIComponent(analysisCurrentView)}`, { replace: true });
    }
  }, [reports, setAnalysisCurrentView, location.search, analysisCurrentView, nav]);

  useEffect(() => {
    if (!user?.email || !reportId) {
      setIsSubscribed(false);
      return;
    }
    setIsSubscribed(dashboardState.reports?.[reportId]?.users?.includes(user.email) || false);
  }, [dashboardState.reports, user?.email, reportId]);

  return (
    <div>
      <Bar label={labels.analysis.title + " | " + labels.analysis.subtitle} />
      <Container
        sx={{
          ...pagesContainerMargins,
          minWidth: GlobalSizes.fullSize,
        }}
      >
        {hasReports && (
          <Tabs
            value={analysisCurrentView ? analysisCurrentView : reports[0]}
            onChange={handleViewChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: GlobalSizes.smallGap,
              borderColor: "divider",
              mb: GlobalSizes.gap,
            }}
          >
            {reports.map((view) => (
              <Tab key={view} label={view} value={view} />
            ))}
          </Tabs>
        )}
        <Box mb={GlobalSizes.gap} position="relative">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mb: GlobalSizes,
            }}
          >
            <Tooltip title={isSubscribed ? "Unsubscribe from daily email about new alerts" : "Subscribe to daily email about new alerts"} arrow>
              <FormControlLabel
                control={<Checkbox checked={isSubscribed} onChange={handleSubscriptionChange} />}
                label={isSubscribed ? "Subscribed to daily email" : "Subscribe to daily email"}
              />
            </Tooltip>
          </Box>
          {!report ? (
            <Box>
              <Typography variant="h5" gutterBottom ml={GlobalSizes.gap}>
                No one has subscribed to this report yet, to see the analysis (within 24 hours), please subscribe...
              </Typography>
              <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
                Real time charts are available in the monitoring page.
              </Typography>
            </Box>
          ) : report?.widgets ? (
            <Box>
              <Typography variant="h5" gutterBottom ml={GlobalSizes.gap}>
                Data filtered from the last 24 hours
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                {report.widgets.map((widget) => {
                  const contents = widget?.widget_contents;
                  if (!contents?.data?.labels?.length || !contents?.data?.datasets) return null;
                  return (
                    <Box
                      width={widget.widget_config?.style?.width ?? undefined}
                      height={widget.widget_config?.style?.height ?? undefined}
                      key={widget.widget_name}
                      sx={{ mb: GlobalSizes.gap }}
                    >
                      <Chart type={contents.type} data={contents.data} options={contents.options} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography variant="h5" gutterBottom ml={GlobalSizes.gap}>
                Analysis will be available within 24 hours...
              </Typography>
              <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
                Real time charts are available in the monitoring page.
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default Analysis;
