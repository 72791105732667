import DocsTable from "./DocsTable";
import SectionCard from "./SectionCard";
import CodeBlock from "./CodeBlock";
import { Box, Typography } from "@mui/material";

export const alertsObjProperties: { "Property Key": string; "Value Type": string; Description: string }[] = [
  { "Property Key": "customer_id", "Value Type": "String", Description: "The team customer ID" },
  { "Property Key": "url", "Value Type": "String", Description: "The original URL of the post, user or article. This is a unique identifier for the alert." },
  { "Property Key": "asset", "Value Type": "String", Description: "The asset associated with the alert." },
  { "Property Key": "avatar", "Value Type": "String", Description: "The profile picture of the user or the logo of the news source." },
  { "Property Key": "source", "Value Type": "String", Description: "The source from where alert was detected." },
  { "Property Key": "detection_date", "Value Type": "Integer", Description: "The timestamp when the alert was detected." },
  { "Property Key": "creation_date", "Value Type": "Integer", Description: "The timestamp indicating when the post, user or article was first published." },
  { "Property Key": "type", "Value Type": "String", Description: "Description:The type of alert." },
  { "Property Key": "impressions", "Value Type": "Integer", Description: "The number of impressions associated with the alert." },
  { "Property Key": "impressions_type", "Value Type": "String", Description: "The type of impressions related to the alert." },
  { "Property Key": "user", "Value Type": "String", Description: "The user who triggered the alert." },
  { "Property Key": "user_id", "Value Type": "String", Description: "The ID of the user related to the alert." },
  { "Property Key": "user_name", "Value Type": "String", Description: "The name of the user related to the alert." },
  { "Property Key": "full_content", "Value Type": "String", Description: "The full content of the alert." },
  { "Property Key": "title", "Value Type": "String", Description: "The title of the alert." },
  { "Property Key": "ai_filter", "Value Type": "Object", Description: "Narratives." },
  { "Property Key": "description_short", "Value Type": "String", Description: "A short description of the alert." },
  { "Property Key": "note", "Value Type": "String", Description: "Additional notes related to the alert." },
  { "Property Key": "severity", "Value Type": "String", Description: "The severity level of the alert." },
  { "Property Key": "location", "Value Type": "String", Description: "The location associated with the alert." },
  { "Property Key": "flagged", "Value Type": "boolean", Description: "Indicates whether the alert has been flagged." },
  { "Property Key": "emails", "Value Type": "Array<String>", Description: "List of email addresses associated with the profile." },
  { "Property Key": "phone_numbers", "Value Type": "Array<String>", Description: "List of phone numbers associated with the profile." },
  { "Property Key": "education", "Value Type": "Array<Object>", Description: "List of education history records." },
  { "Property Key": "work_history", "Value Type": "Array<Object>", Description: "List of work experience records." },
  { "Property Key": "location_history", "Value Type": "Array<String>", Description: "List of previous locations associated with the profile." },
  { "Property Key": "gender", "Value Type": "String", Description: "Gender of the profile owner." },
];

const alertResponseExample: string = `{
    "customerId": "abcde12345",
    "count": 3,
    "alerts": [
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        },
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        },
        {
            "exampleKey1": 1,
            "exampleKey2": 2
        }
    ],
    "nextPageToken": "eyJ1cmwiOiJodHRwczov..."
  `;

const Alerts = () => {
  return (
    <SectionCard title="Alerts">
      <Typography variant="body1">
        <Box sx={{ fontWeight: "bold" }}>
          <u>Method:</u> <span style={{ color: "#eb9234", fontWeight: "700" }}>POST</span>
        </Box>
        <Box sx={{ fontWeight: "bold" }}>
          <u>Path:</u> /alerts
        </Box>
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom>
        Body
      </Typography>
      <DocsTable
        data={[
          { "Property Key": "limit", Description: "An integer greater than 0 that limits the number of alerts fetched in a single request. (Optional)" },
          {
            "Property Key": "nextPageToken",
            "Value Type": "String",
            Description:
              "The nextPageToken is included in the response when there are additional pages. Pass it in the request body to retrieve the next page.",
          },
        ]}
      />

      <br />
      <Typography variant="h6" gutterBottom>
        Pagination:
      </Typography>
      <Typography variant="body1" paragraph>
        If a single request does not retrieve all alerts, the response will include a nextPageToken. To fetch subsequent pages, include the nextPageToken in the
        request body of your next request. The final page in the results will not include a nextPageToken, indicating there are no more pages to retrieve.
      </Typography>
      <br />
      <Typography variant="h6">Request Body Example:</Typography>
      <CodeBlock
        code={`{ 
          "limit": 3, 
          "nextPageToken": "eyJ1cmwiOiJodHRwczov..." 
}`}
      />
      <br />
      <Typography variant="h6">Response Body Example:</Typography>
      <CodeBlock code={alertResponseExample} />
      <br />
      <Typography variant="h5" gutterBottom>
        Object Properties (Response)
      </Typography>
      <DocsTable data={alertsObjProperties} />
    </SectionCard>
  );
};

export default Alerts;
