import { AssetType, entries } from "../../state";
import { IconButton, Tooltip, Menu, MenuItem, Box, FormControlLabel, Switch, ListItemIcon, Typography, Stack } from "@mui/material";
import { useWsContext } from "../../ws-context";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import React, { useState, useMemo } from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TagIcon from "@mui/icons-material/Tag";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { SOCIAL_INFO, unixToLocaleDate } from "../../labels";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GlobalSizes } from "../../size";
import AlertsCounter from "../AlertsCounter";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatDistance } from "date-fns";

function AssetCard({ asset, value, deleteAsset }: { asset: string; value: AssetType; deleteAsset: (asset: string) => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mentionsState, dashboardState, updateDashboard, emitNewData } = useWsContext();

  const isMenuOpen = Boolean(anchorEl);

  const relevantAlerts = useMemo(
    () => mentionsState.filter((mention) => mention.asset === asset).sort((a, b) => (b.creation_date || 0) - (a.creation_date || 0)),
    [asset, mentionsState]
  );

  const renderTimeAgoGroups = (groupedSourcesByTime: Map<string, string[]>) => {
    return Array.from(groupedSourcesByTime.entries()).map(([timeAgoLabel, sources]) => (
      <div key={timeAgoLabel}>
        <span style={{ textDecoration: "underline" }}>{timeAgoLabel}</span>:
        <ul style={{ marginTop: GlobalSizes.smallGap }}>
          {sources.map((source) => (
            <li key={source}>{source}</li>
          ))}
        </ul>
      </div>
    ));
  };

  const moreInfo = useMemo(() => {
    if (Object.keys(value.sources_last_crawled || {}).length === 0) return null;

    const sourceEntries = entries(value.sources_last_crawled).sort(([, timeA], [, timeB]) => timeB - timeA);
    const groupedSourcesByTime = Object.groupBy(sourceEntries, ([, timestamp]) => formatDistance(new Date(timestamp), new Date(), { addSuffix: true }));
    const timeGroupMap = new Map(Object.entries(groupedSourcesByTime).map(([timeLabel, entries]) => [timeLabel, (entries ?? []).map(([source]) => source)]));

    return renderTimeAgoGroups(timeGroupMap);
  }, [value.sources_last_crawled]);

  const handleAssetActivity = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    updateDashboard({
      assets: { ...dashboardState.assets, [asset]: { ...value, disabled: !checked } },
    });
    emitNewData({
      action: "setAsset",
      asset: {
        [asset]: { ...value, disabled: !checked },
      },
    });
  };

  const textColor = value.disabled ? "text.disabled" : "text.primary";

  return (
    <ListItem
      sx={{ my: GlobalSizes.gap }}
      divider
      secondaryAction={
        <IconButton id="more-button" onClick={(event) => setAnchorEl(event.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
      }
    >
      <ListItemAvatar sx={{ color: textColor }}>{searchType(asset).icon}</ListItemAvatar>
      <Tooltip title={moreInfo}>
        <ListItemText
          primaryTypographyProps={{
            color: textColor,
          }}
          secondaryTypographyProps={{
            color: textColor,
          }}
          primary={searchType(asset).format(asset)}
          secondary={<React.Fragment>{searchType(asset).label}</React.Fragment>}
        />
      </Tooltip>
      <Stack direction="column" mr={GlobalSizes.gap} gap={GlobalSizes.smallGap}>
        <AlertsCounter alerts={relevantAlerts} />
        {value.created_by && (
          <Box alignSelf="flex-end">
            <Tooltip title="Created by">
              <Typography variant="caption" color="text.secondary">
                {`${value.created_by} | ${unixToLocaleDate(value.creation_date)}`}
              </Typography>
            </Tooltip>
          </Box>
        )}
      </Stack>
      <Menu id="menu" anchorEl={anchorEl} open={isMenuOpen} onClose={() => setAnchorEl(null)}>
        <MenuItem key="switch">
          <FormControlLabel
            control={<Switch size="small" checked={!value.disabled} onChange={handleAssetActivity} />}
            label={value.disabled ? "Disabled" : "Enabled"}
          />
        </MenuItem>
        <MenuItem key="delete" data-track="remove_asset" data-event-track={asset} onClick={() => deleteAsset(asset)}>
          <Tooltip title={`Delete ${asset} (does not delete alerts)`}>
            <ListItemIcon sx={{ gap: GlobalSizes.gap, color: "inherit" }}>
              <DeleteIcon fontSize="small" />
              Delete
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
      </Menu>
    </ListItem>
  );
}

const searchType = (asset: string | null) => {
  const defaultType = (overrides: Partial<{ label: string; format: (asset: string) => string; icon: JSX.Element | null }>) => ({
    label: "Monitor this item",
    format: (asset: string) => asset,
    icon: <GpsFixedIcon />,
    ...overrides,
  });

  if (!asset) return defaultType({ label: 'Enter term, user, #hashtag or "exact phrase" to monitor', icon: null });

  if (asset.includes("https://")) {
    try {
      const url = new URL(asset);
      const firstPart = url.pathname.split("/")[1];
      const secondPart = url.pathname.split("/")[2];
      const domain = url.hostname.replace("www.", "");
      const mainDomain = Object.keys(SOCIAL_INFO).find((key) => SOCIAL_INFO[key].alternate === domain) || domain;
      const SelectedIcon = SOCIAL_INFO[mainDomain]?.icon;

      const isGroup = firstPart === "groups";
      const isChannel = mainDomain === "youtube.com" && (firstPart === "channel" || firstPart.startsWith("@"));
      const isUser =
        (mainDomain === "linkedin.com" && firstPart === "in") ||
        (mainDomain === "instagram.com" && firstPart && !secondPart) ||
        (mainDomain === "tiktok.com" && firstPart?.startsWith("@") && !secondPart) ||
        ((mainDomain === "twitter.com" || mainDomain === "x.com") && firstPart && !secondPart) ||
        (mainDomain === "facebook.com" && firstPart && !firstPart.startsWith("story") && !secondPart);

      const label = !SelectedIcon
        ? "Monitor this URL"
        : isGroup
        ? "Monitor this group"
        : isChannel
        ? "Monitor this channel"
        : isUser
        ? "Monitor this user"
        : "Monitor this URL";

      return defaultType({ label, icon: SelectedIcon ? <SelectedIcon /> : <GpsFixedIcon /> });
    } catch {
      return defaultType({ label: "Monitor this item", icon: <GpsFixedIcon /> });
    }
  }

  if (asset.startsWith("#")) return defaultType({ label: "Monitor this #hashtag", icon: <TagIcon /> });
  if (asset.startsWith("@")) return defaultType({ label: "Monitor this @userName", icon: <AccountCircleIcon /> });
  if (asset.startsWith('"') && asset.endsWith('"')) {
    return defaultType({ label: 'Monitor this "exact phrase"', icon: <FormatQuoteIcon /> });
  }

  if (asset.includes(" ")) {
    return defaultType({
      label: "Monitor items that contain ALL of these words",
      icon: <SpeakerNotesIcon />,
      format: (asset: string) => asset.replaceAll(" ", " ➕ "),
    });
  }

  return defaultType({});
};

export { AssetCard, searchType };
