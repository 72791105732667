import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { MentionType, MitigationType } from "../../state";
import { GlobalSizes } from "../../size";
import { Check } from "@mui/icons-material";
import { MITIGATIONS } from "../../labels";
import MitigationTabPanel from "./MitigationTabPanel";
import { MitigationEntry, MitigationKeys } from "../../Pages/Action";
import { useWsContext } from "../../ws-context";

interface MitigationTabsProps {
  selectedTab: string | undefined;
  setSelectedTab: React.Dispatch<React.SetStateAction<string | undefined>>;
  shouldRenderMitigationButton: (mitigation: MitigationEntry, alert: MentionType) => boolean;
  getFirstTab: (alert: MentionType) => string | undefined;
}

const MitigationTabs = ({ selectedTab, setSelectedTab, shouldRenderMitigationButton, getFirstTab }: MitigationTabsProps) => {
  const { currentAlert } = useWsContext();
  if (!currentAlert) return null;

  return (
    <Stack>
      <Typography mb={GlobalSizes.gap} variant="h5">
        Mitigation
      </Typography>
      <Typography>Threat mitigation options</Typography>
      <Stack direction="column" gap={GlobalSizes.gap}>
        <Tabs
          value={
            selectedTab && shouldRenderMitigationButton(MITIGATIONS[selectedTab as MitigationKeys], currentAlert) ? selectedTab : getFirstTab(currentAlert)
          }
          onChange={(e, newValue) => {
            setSelectedTab(newValue);
            window.location.hash = encodeURIComponent(newValue);
          }}
          aria-label="mitigation tabs"
        >
          {Object.entries(MITIGATIONS).map(([mitigationKey, mitigation]) => {
            if (!shouldRenderMitigationButton(mitigation, currentAlert)) return null;
            const mitigationContent = currentAlert[mitigation.promptId as keyof MentionType] as string;
            const hasContent = !!mitigationContent?.length;
            return (
              <Tab
                key={mitigationKey}
                label={
                  <Box sx={{ position: "relative" }}>
                    {mitigationKey}
                    {hasContent && <Check sx={{ fontSize: GlobalSizes.largeSize, position: "absolute", right: "-16px" }} />}
                  </Box>
                }
                value={mitigationKey}
                aria-label={mitigationKey}
              />
            );
          })}
        </Tabs>
        {Object.entries(MITIGATIONS).map(([mitigationKey, mitigation]) => {
          const shouldRender = currentAlert.url && shouldRenderMitigationButton(mitigation, currentAlert) && selectedTab === mitigationKey;
          if (!shouldRender) return null;
          const promptId = mitigation.promptId;
          return (
            <MitigationTabPanel
              key={mitigationKey}
              mitigation={mitigationKey as MitigationKeys}
              mitigationText={currentAlert[promptId as keyof MitigationType]}
              alert={currentAlert}
              showReplyInPlatform={
                (mitigationKey === "counter narrative" || mitigationKey === "alternate narrative") &&
                currentAlert.type === "post" &&
                currentAlert.source === "twitter.com"
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default MitigationTabs;
