import { GTranslateOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
import { translateButtonSizes } from "../size";

const Translate = ({ language, content }: { language?: string; content?: string }) => {
  if (language === "English" || !content) {
    return null;
  }

  const translate = `https://translate.google.com/?sl=auto&tl=en&text=${encodeURIComponent(content)}&op=translate`;

  return (
    <Link href={translate} sx={translateButtonSizes} target="_blank" rel="noopener noreferrer">
      <GTranslateOutlined fontSize="inherit" sx={{ mr: 0.2 }} />
      Translate
    </Link>
  );
};

export default Translate;
