import Box from "@mui/material/Box";
import { PieChart } from "@mui/x-charts/PieChart";
import { useWsContext } from "../../../ws-context";
import { useMemo } from "react";
import { GlobalSizes } from "../../../size";
import { Typography } from "@mui/material";
import { MentionType } from "../../../state";

const COLORS = ["#FF6B6B", "#FFA07A", "#FFD93D", "#6BCB77", "#4D96FF", "#A66DD4", "#F368E0", "#3DC1D3", "#FFC857", "#FF9A9E"];

const TopContentCreatorsPieChart = ({ mentions }: { mentions: MentionType[] }) => {
  const { setSidebarAlert } = useWsContext();

  const { usersSeries, postsSeries } = useMemo(() => {
    const mentionsByUser = {} as { [user: string]: { url: string; impressions: number }[] };

    mentions.forEach(({ type, impressions, user, url }) => {
      if (type !== "post" || !user || !impressions) return;
      (mentionsByUser[user] ||= []).push({ url, impressions: Number(impressions) });
    });

    const topUsers = Object.entries(mentionsByUser)
      .map(([user, posts]) => ({
        user,
        totalImpressions: posts.reduce((acc, { impressions }) => acc + impressions, 0),
        posts,
      }))
      .sort((a, b) => b.totalImpressions - a.totalImpressions)
      .slice(0, 10);

    const userColorMap = Object.fromEntries(topUsers.map(({ user }, i) => [user, COLORS[i % COLORS.length]]));

    return {
      usersSeries: topUsers.map(({ user, totalImpressions }) => ({
        id: user,
        label: user,
        value: totalImpressions,
        color: userColorMap[user],
      })),
      postsSeries: topUsers.flatMap(({ user, posts }) =>
        posts.map(({ url, impressions }, i) => ({
          id: url,
          label: `${user} - ${url}`,
          value: impressions,
          color: userColorMap[user],
        }))
      ),
    };
  }, [mentions]);

  if (!postsSeries.length) return null;
  
  function getLabel(item: { label?: string | undefined }) {
    if (!item.label) return "";
    if (item.label.length < 7) return item.label;
    return item.label?.slice(0, 7) + "...";
  }
  return (
    <Box width={GlobalSizes.cards.width} height={GlobalSizes.fullScreenHeight} ml={GlobalSizes.gap}>
      <Typography variant="h6" gutterBottom>
        {`Top 10 Content Creators (${postsSeries.length} alerts)`}
      </Typography>
      <Typography variant="caption" display="block">
        {`The inner circle highlights the top 10 creators by total post exposure`}
      </Typography>
      <Typography variant="caption" display="block">
        {`The outer circle breaks down their posts and impact`}
      </Typography>
      <PieChart
        series={[
          {
            innerRadius: 0,
            outerRadius: 150,
            id: "users-series",
            data: usersSeries,
            highlightScope: { fade: "series", highlight: "item" },
            faded: { color: "gray" },
            arcLabel: (item) => getLabel(item),
            arcLabelMinAngle: 35,
          },
          {
            innerRadius: 170,
            outerRadius: 200,
            id: "posts-series",
            data: postsSeries,
            highlightScope: { fade: "series", highlight: "item" },
            faded: { color: "gray" },
          },
        ]}
        height={600}
        onItemClick={(_, __, item) => setSidebarAlert(mentions.find((m) => m.url === item.id))}
        slotProps={{ legend: { hidden: true } }}
      />
    </Box>
  );
};

export default TopContentCreatorsPieChart;
