import { Typography, Card, CardContent } from "@mui/material";

const SectionCard = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Card variant="outlined" sx={{ mb: 4, maxWidth: "1500px" }}>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      {children}
    </CardContent>
  </Card>
);

export default SectionCard;
