import { useMemo } from "react";
import { MentionType } from "../../../state";
import PieChartView from "./PieChartView";
import { useClientContext } from "../../../client-context";

interface NarrativesPieChartProps {
  mentions: MentionType[];
}

const accumulateInquiries = (mentions: MentionType[]) => {
  const inquiryMap = new Map<string, number>();
  mentions.forEach((mention) => {
    mention.ai_filter?.results?.forEach((result) => {
      inquiryMap.set(result.key, (inquiryMap.get(result.key) || 0) + 1);
    });
  });
  return Array.from(inquiryMap, ([id, value]) => ({ id, value }));
};

function NarrativesPieChart({ mentions }: NarrativesPieChartProps) {
  const { stringToColor } = useClientContext();
  const pieData = useMemo(() => {
    return accumulateInquiries(mentions).map(({ id, value }) => ({
      id,
      value,
      label: id,
      color: stringToColor(id),
    }));
  }, [mentions, stringToColor]);
  return <PieChartView title={`Top 10 Narratives (${mentions.length} alerts)`} data={pieData} />;
}

export default NarrativesPieChart;
