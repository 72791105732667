import { Tooltip, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useMemo, useRef, useState } from "react";
import WordCloud from "wordcloud";
import { GlobalSizes } from "../../size";
import { MentionType } from "../../state";
import nlp from "compromise";
import stopwords from "stopwords-iso";
import unicodeProperties from "unicode-properties";

function WordCloudChart({ mentions }: { mentions: MentionType[] }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [hoveredWord, setHoveredWord] = useState<{ text: string; count: number } | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [originalCounts, setOriginalCounts] = useState<{ [key: string]: number }>({});
  const stopWords = useMemo(() => [...stopwords["en"], ...stopwords["he"]], []);

  const extractRelevantWords = (words: string[]) => {
    const text = words.join(" ");
    const doc = nlp(text);
    // Extract nouns, adjectives, and verbs
    const relevantWords = [...doc.nouns().out("array"), ...doc.adjectives().out("array"), ...doc.verbs().out("array")];
    return relevantWords;
  };

  const words = useMemo(() => {
    const wordMap = new Map<string, number>();
    const realCounts: { [key: string]: number } = {};

    // List of Logographic & Syllabic scripts to exclude
    const excludedScripts = new Set([
      "Han", // Chinese, Japanese Kanji, Korean Hanja
      "Hiragana", // Japanese Hiragana
      "Katakana", // Japanese Katakana
      "Thai", // Thai script
      "Khmer", // Khmer script
      "Lao", // Lao script
      "Myanmar", // Burmese script
    ]);

    mentions.forEach((mention) => {
      if (mention.type === "group") return;
      let words = mention
        .description_short!.toLowerCase()
        .replace(/’/g, "'")
        .split(/\s+/)
        .map((word) => word.replace(/[,.!?'-]+$/, "")); // Remove punctuation from end of word

      if (mention.language === "English") {
        words = extractRelevantWords(words);
      }

      const isLogographicOrSyllabic = (word: string) => Array.from(word).some((char) => excludedScripts.has(unicodeProperties.getScript(char.codePointAt(0)!)));

      words = words.filter((word) => !isLogographicOrSyllabic(word)); // Remove logographic and syllabic characters
      words = words.filter((word) => !stopWords.includes(word));
      const uniqueWords = new Set<string>();

      // Count words that are not stop words or numbers
      words.forEach((word) => {
        if (word.length > 2 && word.length < 50 && !/^\d+$/.test(word)) {
          // limit word length to 50 and exclude numbers
          const formattedWord = word.charAt(0).toUpperCase() + word.slice(1); // Capitalize first letter
          if (!uniqueWords.has(formattedWord)) {
            uniqueWords.add(formattedWord);
            wordMap.set(formattedWord, (wordMap.get(formattedWord) || 0) + 1);
            realCounts[formattedWord] = (realCounts[formattedWord] || 0) + 1; // Keep track of original counts
          }
        }
      });
    });

    setOriginalCounts(realCounts);

    return Array.from(wordMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 50);
  }, [mentions, stopWords]);

  useEffect(() => {
    const averageCount = words.length > 0 ? Math.floor(words.reduce((acc, [_, count]) => acc + count, 0) / words.length) : 0;
    if (!canvasRef.current || words.length === 0) return;
    const canvas = canvasRef.current;
    const canvasWidth = GlobalSizes.cards.width;
    const canvasHeight = 500;
    const PADDING = 20;
    canvas.width = canvasWidth - PADDING;
    canvas.height = canvasHeight - PADDING;
    WordCloud(canvas, {
      list: words,
      gridSize: averageCount < 15 ? 25 : 10,
      weightFactor: (size) => (averageCount < 15 ? Math.max(15, size * 1.5) : Math.min(200, size * 1.5)),
      fontFamily: "Montserrat",
      color: () => {
        return `hsl(${Math.floor(Math.random() * 360)}, 85%, 60%)`;
      },
      rotateRatio: 0.5,
      rotationSteps: 2,
      backgroundColor: "transparent",
      drawOutOfBound: false,
      shrinkToFit: true,
      hover: function (item, _, event) {
        if (item) {
          setHoveredWord({ text: item[0], count: originalCounts[item[0]] || 0 });
          setTooltipPosition({ x: event.pageX, y: event.pageY });
        } else {
          setHoveredWord(null);
        }
      },
    });
  }, [words, originalCounts]);

  return (
    <Box height={GlobalSizes.fullScreenHeight}>
      <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
        Word Cloud (From {mentions.length} alerts)
      </Typography>
      {hoveredWord && (
        <Tooltip
          open
          title={
            <Box>
              <Typography variant="caption" display="block">
                {hoveredWord.text}
              </Typography>
              <Typography variant="caption">Count: {hoveredWord.count}</Typography>
            </Box>
          }
        >
          <Box
            sx={{
              position: "absolute",
              top: tooltipPosition.y + 5,
              left: tooltipPosition.x + 5,
            }}
          />
        </Tooltip>
      )}
      <canvas ref={canvasRef} style={{ display: "block", margin: "auto" }} />
    </Box>
  );
}

export default WordCloudChart;
