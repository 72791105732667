import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import RefreshButton from "../RefreshButton";
import { labels } from "../../labels";
import { useCallback, useState } from "react";
import { MentionType } from "../../state";
import { useWsContext } from "../../ws-context";
import { Link } from "react-router-dom";

const MitigationButtons = () => {
  const [isLoading, setIsLoading] = useState<{ takedown: boolean; dossier: string }>({
    takedown: false,
    dossier: "",
  });
  const [dossierError, setDossierError] = useState(false);

  const { mentionsState, updateAlerts, emitNewData, socketRef, currentAlert } = useWsContext();

  const handleTakedown = async (alert: MentionType) => {
    emitNewData({ action: "takedown", url: currentAlert?.url });
    updateAlerts({ ...alert, takedown_status: "submitted" });
  };

  const createDossierFile = async (alert: MentionType) => {
    setIsLoading((prev) => ({ ...prev, dossier: "loading" }));
    setDossierError(false);
    updateAlerts({ ...alert, mitigation_overview: undefined });
    emitNewData({ action: "dossier", url: alert.url });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === alert.url && data.updatedAlert.mitigation_overview) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "dossier" && data.error.details.url === alert.url) {
        setIsLoading((prev) => ({ ...prev, dossier: "" }));
        setDossierError(true);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  const hasChildAlerts = useCallback(
    (parentMention: string) => {
      return mentionsState.some((m) => m.parent_mention === parentMention);
    },
    [mentionsState]
  );

  if (!currentAlert) return null;

  return (
    <Stack sx={{ flexDirection: "row", gap: GlobalSizes.mediumGap, marginTop: GlobalSizes.gap }}>
      <Stack>
        <Button variant="contained" size="large" color="secondary" onClick={() => handleTakedown(currentAlert)} disabled={!!currentAlert.takedown_status}>
          <Stack alignItems="self-start">
            <Typography>Takedown</Typography>
            <Typography variant="caption">social platform removal request</Typography>
            <Typography variant="caption">{currentAlert.takedown_status}</Typography>
          </Stack>
        </Button>
        {isLoading.takedown && <CircularProgress />}
      </Stack>
      {hasChildAlerts(currentAlert.url) && (
        <Stack>
          <Button disabled={isLoading.dossier === "loading"} variant="contained" size="large" onClick={() => createDossierFile(currentAlert)}>
            <Stack alignItems="self-start">
              <Typography>Overview</Typography>
              <Typography variant="caption">Generate investigation file</Typography>
            </Stack>
          </Button>
          {currentAlert.mitigation_overview && !isLoading.dossier && (
            <Link target="_blank" to={currentAlert.mitigation_overview}>
              Overview file
            </Link>
          )}
          {isLoading.dossier === "loading" && <CircularProgress sx={{ m: GlobalSizes.smallGap }} />}
        </Stack>
      )}
      {isLoading.dossier === "processing" && <RefreshButton label={labels.takeAction.overview} />}
      {dossierError && <Typography color="error">Failed generating overview, please try again</Typography>}
    </Stack>
  );
};

export default MitigationButtons;
