import { useMemo } from "react";
import { entries, MentionType } from "../state";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Avatar, AvatarGroup, Badge, Stack } from "@mui/material";
import { SOCIAL_INFO } from "../labels";
import { Newspaper } from "@mui/icons-material";
import { useWsContext } from "../ws-context";
import { GlobalSizes } from "../size";

const AlertsCounter = ({ alerts }: { alerts: MentionType[] }) => {
  const { setSidebarAlert } = useWsContext();

  const counter = useMemo(() => {
    return alerts.reduce(
      (acc, alert) => {
        const alertSeverity = alert.manual_severity || alert.severity;
        alertSeverity && acc.severity[alertSeverity]++;
        alert.hidden && acc.archive++;
        alert.flagged && acc.flagged++;
        acc.total++;
        return acc;
      },
      { severity: { low: 0, medium: 0, high: 0, critical: 0 }, archive: 0, flagged: 0, total: 0 }
    );
  }, [alerts]);

  const displayedAlerts = useMemo(() => alerts.slice(0, 5), [alerts]);

  return (
    <Stack spacing={GlobalSizes.smallGap}>
      <Tooltip title="Alert stats">
        <Typography sx={{ alignSelf: "flex-end" }} variant="caption">
          {entries(counter).map(([key, val]) => {
            if (key === "severity")
              return entries(val).map(([severity, count]) => {
                return !!count && `${severity}:${count} `;
              });
            return !!val && `${key}:${val} `;
          })}
        </Typography>
      </Tooltip>
      <AvatarGroup max={5}>
        {displayedAlerts.map((alert) => {
          const SelectedIcon = SOCIAL_INFO[alert?.source || ""]?.icon || Newspaper;
          return (
            <Tooltip key={alert.url} title={alert.user + " " + alert.description_short || alert.full_content}>
              <Badge key={alert.url} overlap="circular" badgeContent={<SelectedIcon fontSize="small" />}>
                <Avatar alt={alert.user} src={alert.avatar} onClick={() => setSidebarAlert(alert)} sx={{ cursor: "pointer" }} />
              </Badge>
            </Tooltip>
          );
        })}
      </AvatarGroup>
    </Stack>
  );
};

export default AlertsCounter;
