import { Box, Card, CardContent, IconButton, Stack, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../size";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import NarrativeFooter from "./NarrativeFooter";
import { useWsContext } from "../../ws-context";
import { AIQuestionType } from "../../state";
import { useAuth0 } from "@auth0/auth0-react";
import NarrativeFieldsForm from "./NarrativeFieldsForm";

interface EditNarrativeProps {
  localQuestions: AIQuestionType[];
  setLocalQuestions: React.Dispatch<React.SetStateAction<AIQuestionType[]>>;
  setInEditMode: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  question: AIQuestionType;
}

const EditNarrative = (props: EditNarrativeProps) => {
  const { localQuestions, setLocalQuestions, setInEditMode, index, error, setError, question } = props;
  const { user } = useAuth0();
  const { dashboardState, updateDashboard, emitNewData } = useWsContext();

  const handleEditQuestion = async (index: number) => {
    const updatedQuestions = localQuestions.map((question, i) =>
      i === index ? { ...question, last_update: Date.now(), last_updated_by: user?.email } : question
    );
    setLocalQuestions(updatedQuestions);
    setInEditMode(-1);
    updateDashboard({ ai_questions: [...updatedQuestions].reverse() });
    emitNewData({
      action: "setAIQuestion",
      questionIndex: (localQuestions.length - 1 - index).toString(),
      question: updatedQuestions[index],
    });
  };

  const handleDeleteQuestion = async (index: number) => {
    if (!window.confirm("Are you sure you want to delete this narrative?")) return;
    const updatedQuestions = localQuestions.filter((_, i) => i !== index);
    setLocalQuestions(updatedQuestions);
    updateDashboard({ ai_questions: updatedQuestions.reverse() });
    setInEditMode(-1);
    emitNewData({
      action: "removeAIQuestion",
      questionIndex: (localQuestions.length - 1 - index).toString(),
    });
  };

  return (
    <Card sx={{ mb: GlobalSizes.gap }}>
      <CardContent sx={{ display: "flex", flexDirection: "column" }}>
        <Box alignSelf="flex-end">
          <Stack direction="row">
            {JSON.stringify(dashboardState.ai_questions?.[localQuestions.length - 1 - index]) !== JSON.stringify(localQuestions[index]) && (
              <Tooltip title="Save" placement="top">
                <IconButton
                  disabled={error}
                  size="small"
                  onClick={() => handleEditQuestion(index)}
                  data-track="edit_narrative"
                  data-event-track={`Changing from ${dashboardState.ai_questions?.[localQuestions.length - 1 - index].label} to ${localQuestions[index].label}`}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete" placement="top">
              <IconButton size="small" onClick={() => handleDeleteQuestion(index)} data-track="delete_narrative" data-event-track={localQuestions[index].label}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel" placement="top">
              <IconButton
                size="small"
                onClick={() => {
                  setInEditMode(-1);
                  setLocalQuestions([...(dashboardState.ai_questions || [])].reverse());
                  setError(false);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
        <NarrativeFieldsForm
          localQuestions={localQuestions}
          setLocalQuestions={setLocalQuestions}
          index={index}
          question={question}
          error={error}
          setError={setError}
        />
      </CardContent>
      <NarrativeFooter question={question} />
    </Card>
  );
};

export default EditNarrative;
