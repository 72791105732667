import { MentionType } from "../../../state";
import { IconButton, Tooltip } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { labels } from "../../../labels";
import { useWsContext } from "../../../ws-context";

function RefreshAiFilter({ alert }: { alert: MentionType }) {
  const { updateAlerts, emitNewData } = useWsContext();

  const deleteAiFilter = async () => {
    const { ai_filter, ...alertAttributes } = alert;
    const newAlert = { ...alertAttributes };
    updateAlerts(newAlert);

    emitNewData({
      action: "removeMultipleAttributes",
      url: alert.url,
      attributes: ["ai_filter", "ai_filter_beta", "similar", "reduced_embedding", "embedding_version"],
    });
  };

  return (
    <Tooltip title={`Retry ${labels.ai.title}`} placement="top">
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          deleteAiFilter();
        }}
      >
        <Refresh />
      </IconButton>
    </Tooltip>
  );
}

export default RefreshAiFilter;
