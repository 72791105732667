import { Box, CardActions, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { unixToLocaleDate } from "../../labels";
import AlertsCounter from "../AlertsCounter";
import { AIQuestionType, MentionType } from "../../state";
import { useWsContext } from "../../ws-context";
import { useMemo } from "react";

const getMentionsByNarrative = (label: string, mentions: MentionType[]) => {
  return mentions?.filter((mention) => mention.ai_filter?.results?.some((result) => result.key === label));
};

const NarrativeFooter = ({ question }: { question: AIQuestionType }) => {
  const { mentionsState } = useWsContext();
  const mentionsByNarrative = useMemo(() => getMentionsByNarrative(question.label, mentionsState), [mentionsState, question.label]);

  return (
    <CardActions sx={{ justifyContent: "flex-end" }}>
      <Stack direction="column" alignItems="center">
        {question.last_update && question.last_updated_by && (
          <Tooltip title="Last edit">
            <Typography variant="caption" color="text.secondary">
              {`${question.last_updated_by} | ${unixToLocaleDate(question.last_update)}`}
            </Typography>
          </Tooltip>
        )}
        {mentionsByNarrative.length > 0 && (
          <Box sx={{ width: GlobalSizes.fullSize }}>
            <Divider sx={{ my: GlobalSizes.smallGap }} />
            <AlertsCounter alerts={mentionsByNarrative} />
          </Box>
        )}
      </Stack>
    </CardActions>
  );
};

export default NarrativeFooter;
