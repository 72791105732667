import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const ButtonClickTracker: React.FC = () => {
  useEffect(() => {
    const handleButtonClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const trackingData: Record<string, string> = {
        innerText: target.innerText,
        elementId: target.id || "",
        className: target.className || "",
        tagName: target.tagName,
      };

      const closestButton = target.closest("[data-track]") as HTMLElement;
      if (closestButton) {
        const { track, eventTrack } = closestButton.dataset;
        if (track && eventTrack) {
          trackingData.event = track;
          trackingData.data = eventTrack;
        }
      }
      mixpanel.track("Click", trackingData);
    };

    document.addEventListener("click", handleButtonClick);
    return () => document.removeEventListener("click", handleButtonClick);
  }, []);

  return null;
};

export default ButtonClickTracker;
