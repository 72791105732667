import ForceGraph3D from "react-force-graph-3d";
import { Box, Typography } from "@mui/material";
import * as THREE from "three";
import MentionFocus from "../../MentionFocus";
import { useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material";
import { useNetworkChart } from "../../../useNetworkChart";
import { getNodeSize, NetworkGraphDataType, NetworkNode } from "./NetworkGraphs";

function EmbeddingsNetworkChart({
  networkGraphData,
  selectedNode,
  onNodeClick,
  onBackgroundClick,
  languagePalette,
  linkColor,
  range,
  formatNodeTooltip,
  formatLinkTooltip,
  getTooltipBgColor,
}: {
  networkGraphData: NetworkGraphDataType;
  selectedNode: string | null;
  onNodeClick: (node: any) => void;
  onBackgroundClick: () => void;
  linkColor: (link: any) => string;
  languagePalette: Record<string, string>;
  range?: [number, number];
  formatNodeTooltip: (node: NetworkNode) => string;
  formatLinkTooltip: (link: any) => string;
  getTooltipBgColor: (link: any) => string;
}) {
  const [imgCache, setImgCache] = useState<{ [key: string]: THREE.Texture }>({});
  const theme = useTheme();
  const { getLinkParticles, getLinkWidth, getLinkParticleWidth } = useNetworkChart(selectedNode);

  const nodeThreeObject = useMemo(() => {
    return (node: any) => {
      const group = new THREE.Group();
      const isImageNode = imgCache[node.image];
      const isInRange = range ? node.creation_date >= range[0] && node.creation_date <= range[1] : true;
      const opacity = isInRange ? 1 : 0.05;
      const impressionsFactor = getNodeSize(node.impressions);

      const isSelected = selectedNode === node.id;
      const selectedFactor = isSelected ? 1.5 : 1;
      if (isImageNode) {
        const sprite = new THREE.Sprite(
          new THREE.SpriteMaterial({
            map: imgCache[node.image],
            opacity,
            depthWrite: false,
          })
        );
        sprite.scale.set(impressionsFactor * 5 * selectedFactor, impressionsFactor * 5 * selectedFactor, 1);
        sprite.renderOrder = 2;
        group.add(sprite);
      } else {
        const sphereMaterial = new THREE.MeshBasicMaterial({
          color: "grey",
          transparent: true,
          opacity,
        });

        const sphere = new THREE.Mesh(new THREE.SphereGeometry(impressionsFactor * selectedFactor), sphereMaterial);
        group.add(sphere);
      }

      const size = 256;
      const canvas = document.createElement("canvas");
      canvas.width = size;
      canvas.height = size;

      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.beginPath();
        ctx.arc(size / 2, size / 2, size / 3, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.clip();
        ctx.fillStyle = languagePalette[node.language] || "grey";
        ctx.fill();
      }

      const texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;
      return group;
    };
  }, [imgCache, range, languagePalette, selectedNode]);

  useEffect(() => {
    // Preload images and cache them with circular masking
    networkGraphData.nodes.forEach((node: any) => {
      if (node.image && !imgCache[node.image]) {
        const canvas = document.createElement("canvas");
        const size = 64;
        canvas.width = size;
        canvas.height = size;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          const img = new Image();
          img.crossOrigin = "Anonymous";

          img.onload = () => {
            ctx.clearRect(0, 0, size, size);
            ctx.save();
            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
            ctx.closePath();
            ctx.clip();

            ctx.drawImage(img, 0, 0, size, size);
            ctx.restore();

            const texture = new THREE.Texture(canvas);
            texture.needsUpdate = true;

            setImgCache((prevCache) => ({ ...prevCache, [node.image]: texture }));
          };

          img.src = node.image;
        }
      }
    });
  }, [imgCache, networkGraphData.nodes]);

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        AI Narrative Network (Showing {networkGraphData.nodes.length} from top 1000 alerts)
      </Typography>
      <ForceGraph3D
        graphData={networkGraphData}
        d3AlphaDecay={0.03} // Slows down stabilization
        d3VelocityDecay={0.4} // Reduces jitter
        cooldownTime={2000} // Limits warm-up duration
        cooldownTicks={0}
        width={window.innerWidth}
        height={window.innerHeight - 98}
        warmupTicks={100}
        enableNodeDrag={false}
        backgroundColor={theme.palette.background.paper}
        nodeLabel={(node) => {
          return `
          <div style="color: black; background-color: #ffffffee; padding: 5px; border-radius: 5px;">
            ${formatNodeTooltip(node)}
          </div>
        `;
        }}
        linkWidth={getLinkWidth}
        linkDirectionalParticles={getLinkParticles}
        linkDirectionalParticleWidth={getLinkParticleWidth}
        linkOpacity={0.8}
        linkLabel={(link) => {
          return `
          <div style="color: black; background-color: ${getTooltipBgColor(link)}; padding: 5px; border-radius: 5px;">
            ${formatLinkTooltip(link)}
          </div>
        `;
        }}
        linkColor={linkColor}
        nodeThreeObject={nodeThreeObject}
        onNodeClick={onNodeClick}
        onBackgroundClick={onBackgroundClick}
        rendererConfig={{
          powerPreference: "high-performance",
        }}
      />
      <MentionFocus />
    </Box>
  );
}

export default EmbeddingsNetworkChart;
