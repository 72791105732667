import { Box, Button, CircularProgress, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { GlobalSizes } from "../../size";
import { Refresh } from "@mui/icons-material";
import { useState } from "react";
import { MITIGATIONS } from "../../labels";
import { useWsContext } from "../../ws-context";
import { AIQuestionType, MentionType } from "../../state";
import { MitigationKeys } from "../../Pages/Action";
import Metadata from "../Metadata";

interface MitigationTabProps {
  mitigation: MitigationKeys;
  mitigationText?: string;
  alert: MentionType;
  showReplyInPlatform: boolean;
}

const getDefaultCounterNarrativeText = (alert: MentionType, narratives?: AIQuestionType[]) => {
  const missingCounterNarratives =
    alert.ai_filter?.results
      ?.filter((narrative) => narrative.content?.booleanAnswer)
      ?.filter((narrative) => !narratives?.some((n) => n.label === narrative.key && n.mitigation))
      .map((narrative) => narrative.key) || [];

  if (!missingCounterNarratives.length) return "";

  return (
    <Typography variant="inherit">
      <Box>For better results, please provide a counter-narrative for each missing narrative, including: {missingCounterNarratives.join(", ")}.</Box>
      You can also check Brinker’s counter-narrative options under the{" "}
      <Link
        href={`/action?url=${encodeURIComponent(alert.url)}#${encodeURIComponent("alternate narrative")}`}
        sx={{ textDecoration: "underline", cursor: "pointer" }}
      >
        Alternate Narrative
      </Link>{" "}
      tab.
    </Typography>
  );
};

function TabPanel({ mitigation, mitigationText, alert, showReplyInPlatform }: MitigationTabProps) {
  const { dashboardState, emitNewData, socketRef, currentAlert } = useWsContext();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function copyMitigationText(mitigation: MitigationKeys) {
    if (!mitigationText) return;
    if (mitigation === "overview") {
      const metadataText = document.getElementById("metadata")?.innerText;
      navigator.clipboard.writeText(`${metadataText}\n\n${mitigationText}`);
      return;
    }
    navigator.clipboard.writeText(mitigationText);
  }

  function openReplyInPlatform() {
    if (!alert.url || !mitigationText) return;
    const tweetNumber = alert.url.split("/").pop();
    if (!tweetNumber) return;
    const replyUrl = `https://twitter.com/intent/tweet?in_reply_to=${tweetNumber}&text=${encodeURIComponent(mitigationText)}`;
    window.open(replyUrl, "_blank");
  }

  const handleMitigation = async (mitigation: MitigationKeys) => {
    if (!currentAlert) return null;
    console.log({ currentAlert });
    console.log("Mitigation request");
    setIsLoading(true);
    setIsError(false);
    emitNewData({ action: "mitigation", url: currentAlert.url, promptId: MITIGATIONS[mitigation].promptId });
    const handleWebSocketMessage = (event: MessageEvent) => {
      const { data } = JSON.parse(event.data);
      if (data?.updatedAlert?.url === currentAlert.url && data.updatedAlert[MITIGATIONS[mitigation].promptId]) {
        setIsLoading(false);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
      if (data?.error?.details?.action === "mitigation" && data.error.details.url === currentAlert.url) {
        setIsLoading(false);
        setIsError(true);
        socketRef.current?.removeEventListener("message", handleWebSocketMessage);
      }
    };
    socketRef.current?.addEventListener("message", handleWebSocketMessage);
  };

  return (
    <Stack direction="column" spacing={GlobalSizes.smallGap} p={GlobalSizes.gap} sx={{ whiteSpace: "pre-wrap" }}>
      <Typography variant="caption" fontSize={15}>
        {mitigation === "counter narrative"
          ? getDefaultCounterNarrativeText(alert, dashboardState.ai_questions)
          : MITIGATIONS[mitigation].mitigationDescription}
      </Typography>
      <Paper sx={{ p: GlobalSizes.gap }}>
        {mitigationText ? (
          <Box>
            {mitigation === "overview" && (
              <Box mb={GlobalSizes.gap}>
                <Metadata alert={alert} />
              </Box>
            )}
            <Typography sx={{ mb: GlobalSizes.smallGap, whiteSpace: "pre-wrap" }}>{mitigationText}</Typography>
            <Tooltip title="Try again" placement="top">
              <Button
                sx={{ borderTopLeftRadius: GlobalSizes.none, borderBottomLeftRadius: GlobalSizes.none }}
                disabled={isLoading}
                onClick={() => handleMitigation(mitigation)}
              >
                {isLoading ? <CircularProgress size={24} /> : <Refresh />}
              </Button>
            </Tooltip>
            <Button onClick={() => copyMitigationText(mitigation)}>Copy to Clipboard</Button>
            {showReplyInPlatform && <Button onClick={openReplyInPlatform}>Reply in Platform</Button>}
          </Box>
        ) : isLoading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" size="large" disabled={isLoading} onClick={() => handleMitigation(mitigation)}>{`Generate ${mitigation}`}</Button>
        )}
        {isError && <Typography color="error">Failed generating mitigation, please try again</Typography>}
      </Paper>
    </Stack>
  );
}

export default TabPanel;
