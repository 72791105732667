import { Box, Container, Typography } from "@mui/material";
import Bar from "../Components/Bar";
import { labels } from "../labels";

import { GlobalSizes, pagesContainerMargins } from "../size";

import ApiDocumentation from "../Components/docs/ApiDocumentation";

function Api() {
  const RunInPostmanButton = () => {
    return (
      <div>
        <a
          href="https://go.postman.co/fork?collection=37789401-2fd1cd76-4737-4a19-8432-3521283e5bdd&workspaceId=2f40ec03-b9be-453d-8b2e-7ac969f17fbf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="https://run.pstmn.io/button.svg" alt="Run in Postman" style={{ width: "150px" }} />
        </a>
      </div>
    );
  };
  return (
    <div>
      <Bar label={labels.api.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Brinker Api
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Welcome to the Brinker API documentation. Here you can find all the information you need to interact with the Brinker API.
        </Typography>

        <ApiDocumentation />
        <Box
          sx={{
            position: "absolute",
            top: 80,
            right: 20,
          }}
        >
          <RunInPostmanButton />
        </Box>
      </Container>
    </div>
  );
}

export default Api;
