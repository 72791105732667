import { Newspaper } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Button, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useCallback } from "react";
import { SimilarAlerts } from "../Components/SimilarAlerts";
import { SOCIAL_INFO } from "../labels";
import { MentionType } from "../state";
import { useWsContext } from "../ws-context";
import AiFilterView from "./investigation/ai-filter/AiFilterView";
import GeneralView from "./investigation/GeneralView";
import TimeLine from "./investigation/TimeLine";
import Note from "./Note";
import SocialEmbed from "./SocialEmbed";
import StatusButtons from "./StatusButtons";
import { GlobalSizes } from "../size";
import MentionSeverity from "./MentionSeverity";
import Engagements from "./Engagements";
import VideoTranscriptView from "./VideoTranscriptView";

function MentionFocus({ nextAlerts, setNextAlerts }: { nextAlerts?: MentionType[]; setNextAlerts?: React.Dispatch<React.SetStateAction<MentionType[]>> }) {
  const theme = useTheme();

  const { updateAlerts, mentionsState, emitNewData, dashboardState, sidebarAlert, setSidebarAlert } = useWsContext();

  const updateAlertRequest = useCallback(
    async (mentionToUpdate: Partial<MentionType>) => {
      const mention = {
        ...mentionToUpdate,
        customer_id: dashboardState.customer_id,
      };

      emitNewData({ action: "updateAlert", mention });
    },
    [dashboardState.customer_id, emitNewData]
  );

  const updateWhitelisted = useCallback(
    (mentionToUpdate: MentionType) => {
      if (mentionToUpdate.whitelisted) {
        const { alertType, ...rest } = mentionToUpdate;
        updateAlerts({ ...rest, severity: "low" });
        updateAlertRequest({ url: mentionToUpdate.url, severity: "low", whitelisted: true });
        return;
      }
      updateAlerts(mentionToUpdate);
      updateAlertRequest({ url: mentionToUpdate.url, whitelisted: false });
    },
    [updateAlertRequest, updateAlerts]
  );

  const handleClose = () => {
    setSidebarAlert(undefined);
  };

  const SelectedIcon = SOCIAL_INFO[sidebarAlert?.source || ""]?.icon || Newspaper;

  if (!sidebarAlert) return null;

  function WhitelistButton({ mention }: { mention: MentionType | undefined }) {
    if (!mention) return null;
    return (
      <Tooltip title={mention.whitelisted ? "Remove from whitelist" : "Add to whitelist"} placement="top">
        <Button
          sx={{ marginTop: GlobalSizes.gap }}
          color={mention.whitelisted ? "success" : "inherit"}
          onClick={() => {
            updateWhitelisted({ ...mention, whitelisted: !mention.whitelisted });
            mention.whitelisted = !mention.whitelisted;
          }}
          aria-label="Whitelist Alert"
          startIcon={<VerifiedUserIcon color={!mention.whitelisted ? "disabled" : "action"} />}
        >
          {mention.whitelisted ? "Whitelisted" : "Add to Whitelist"}
        </Button>
      </Tooltip>
    );
  }

  return (
    <SwipeableDrawer key={sidebarAlert?.url} variant="persistent" open={!!sidebarAlert} onClose={handleClose} anchor={"right"} onOpen={console.log}>
      <Box sx={{ width: 600, position: "relative", p: "0px 16px" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Toolbar disableGutters>
            <SelectedIcon />
            <Box sx={{ flexGrow: 0.01 }} />
            <Typography fontWeight={"bold"} marginRight={3}>
              {sidebarAlert?.source}
            </Typography>
            <MentionSeverity alert={sidebarAlert} />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <StatusButtons alert={sidebarAlert} nextAlerts={nextAlerts || []} setNextAlerts={setNextAlerts} />
          <Divider sx={{ marginTop: GlobalSizes.smallGap }} />
        </Box>
        <GeneralView alert={sidebarAlert} />
        <Box sx={{ overflow: "auto" }}>
          <SocialEmbed source={sidebarAlert?.source} url={sidebarAlert?.url} urn={sidebarAlert?.urn} />
        </Box>
        <VideoTranscriptView alert={sidebarAlert} />
        <AiFilterView alert={sidebarAlert} />
        <Note mention={sidebarAlert} />
        <SimilarAlerts alert={sidebarAlert} allMentions={mentionsState} />
        <Engagements alert={sidebarAlert} allMentions={mentionsState}/>
        <TimeLine alert={sidebarAlert} />
        <WhitelistButton mention={sidebarAlert} />
      </Box>
    </SwipeableDrawer>
  );
}
export default MentionFocus;
