import { useMemo } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Box, Typography, Tooltip } from "@mui/material";
import { GlobalSizes } from "../../../size";
import CustomLegend from "../CustomLegend";

interface PieChartViewProps {
  title: string;
  data: { id: string; value: number; color: string }[];
}

function PieChartView({ title, data }: PieChartViewProps) {
  const pieData = useMemo(() => {
    return data.sort((a, b) => b.value - a.value).slice(0, 10);
  }, [data]);

  return (
    <Box width={GlobalSizes.cards.width} height={GlobalSizes.fullScreenHeight}>
      <Tooltip title="Filtered in the monitoring view">
        <Typography variant="h6" gutterBottom ml={GlobalSizes.gap}>
          {title}
        </Typography>
      </Tooltip>
      <CustomLegend labels={pieData.map((item) => ({ name: item.id, color: item.color }))} />
      <PieChart
        series={[{ data: pieData, arcLabel: "value", arcLabelRadius: GlobalSizes.midSize }]}
        slotProps={{
          legend: { hidden: true },
        }}
        height={GlobalSizes.chartHeight}
        skipAnimation
      />
    </Box>
  );
}

export default PieChartView;
