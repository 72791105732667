import { Box, Typography } from "@mui/material";
import { MentionType } from "../state";

const Metadata = ({ alert }: { alert?: MentionType }) => {
  if (!alert) return null;

  const { creation_date, source, user = "unknown user", description_short = "...", url, impressions, impressions_type, type } = alert;

  const formatDateTime = (dateToFormat: number) => {
    const date = new Date(dateToFormat);
    const formattedDate = date.toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" });
    const formattedTime = date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    return `On ${formattedDate} at ${formattedTime}`;
  };

  const getFormattedSource = (src?: string) => {
    if (!src) return "unknown source";
    const sourceName = src.split(".")[0];
    return sourceName.toLowerCase().includes("twitter") ? "X" : sourceName;
  };

  const creationDateSection = creation_date ? formatDateTime(creation_date) : "On an unknown date";
  const formattedImpressionsType = impressions ? impressions_type?.split("_")[1] : "";
  const impressionsSection = impressions
    ? `The ${type?.toLowerCase()} has ${impressions.toLocaleString()} ${formattedImpressionsType}.`
    : "The number of impressions is unknown.";

  return (
    <Box id="metadata">
      <Typography component="span">{`${creationDateSection}, `}</Typography>
      <Typography component="span" sx={{ textTransform: "capitalize" }}>
        {getFormattedSource(source)}
      </Typography>
      <Typography component="span">{` user ${user} shared content stating "${description_short}" [${url}]. ${impressionsSection}`}</Typography>
    </Box>
  );
};

export default Metadata;
