import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { MentionType } from "../state";
import { Slide, Stack, useTheme } from "@mui/material";
import { forwardRef } from "react";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalSizes } from "../size";
import Graphs from "./Graphs";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenChartsDialog({
  filteredMentions,
  setShowFullScreenCharts,
  showFullScreenCharts = false,
}: {
  filteredMentions: MentionType[];
  setShowFullScreenCharts: React.Dispatch<React.SetStateAction<boolean>>;
  showFullScreenCharts?: boolean;
}) {
  const theme = useTheme();

  return (
    <Dialog fullScreen open={showFullScreenCharts} onClose={() => setShowFullScreenCharts(false)} TransitionComponent={Transition}>
      <Box pt={GlobalSizes.gap} sx={{ backgroundColor: theme.palette.background.paper, overflow: "hidden" }}>
        <Stack position="absolute" right={10} direction="row" alignItems="center" gap={GlobalSizes.gap} p={GlobalSizes.smallGap}>
          <IconButton
            sx={{ ml: GlobalSizes.gap * 2, mb: GlobalSizes.gap }}
            edge="start"
            color="inherit"
            onClick={() => setShowFullScreenCharts(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        {filteredMentions.length > 0 && <Graphs mentions={filteredMentions} />}
      </Box>
    </Dialog>
  );
}

export default FullScreenChartsDialog;
