import { Typography, Container } from "@mui/material";
import { GlobalSizes, pagesContainerMargins } from "../size";
import { UsageStats } from "../Components/home/UsageStats";
import { useWsContext } from "../ws-context";
import Bar from "../Components/Bar";
import { labels } from "../labels";

function Home() {
  const { mentionsState } = useWsContext();

  return (
    <div>
      <Bar label={labels.home.title + " | " + labels.home.subtitle} />
      <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize, marginTop: "5px" }}>
        <Container sx={{ ...pagesContainerMargins, minWidth: GlobalSizes.fullSize }}>
          <Typography variant="h4" gutterBottom>
            Welcome to Brinker.ai
          </Typography>
          <UsageStats filteredMentions={mentionsState} />
          <Typography fontSize={8}>
            <a href="/push-data.json" target="_blank" rel="noopener noreferrer">
              Version data
            </a>
          </Typography>
        </Container>
      </Container>
    </div>
  );
}

export default Home;
